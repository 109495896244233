import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { newEmailVerify } from '../../../server/server'
import Loading from '../../../components/Common/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../auth/UseUser'
import HelpButton from '../../../components/Common/HelpButton'

type Inputs = {
    email: string
}

const EmailChangeStep1 = () => {
    const {
        register,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: '',
        },
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>('')
    const { setUser } = useUser()
    const navigate = useNavigate()

    const onSubmit: SubmitHandler<Inputs> = async () => {
        setLoading(true)
        sendPwRecoveryEmail()
    }
    // const logout = () => {
    //     localStorage.clear()
    //     setUser({})
    //     navigate('/login')
    // }

    const sendPwRecoveryEmail = async () => {
        const resp = await newEmailVerify(getValues('email'))
        if (resp.status === 201) {
            // logout()
            navigate(`/validation/send?email=${getValues('email')}`)
        } else if (resp.status === 409) {
            setError('inUse')
        } else {
            setError('error')
        }
        setLoading(false)
    }

    return (
        <div className="flex h-full justify-center items-center flex-col">
            <h2 className="mb-[32px] text-[24px] leading-[28px] font-light">Измена имејл адресе</h2>
            <form className="w-full flex flex-col justify-center items-center min-w-[520px] max-w-[520px]" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full flex flex-row items-end justify-between mb-[5px]">
                    <label>Унесите нову имејл адресу</label>
                    <HelpButton name="email_change_help" />
                </div>
                <input
                    className="border border-[#0000003B] min-w-[520px] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                    placeholder="Емаил"
                    {...register('email', {
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address',
                        },
                    })}
                />
                <span
                    className={`${
                        error === 'success' ? '' : error || Object.keys(errors).length > 0 ? 'text-[#d32f2f]' : ''
                    } text-[12px] leading-5 h-10 text-center mt-[10px] flex flex-col max-w-[500px]`}
                >
                    <p>{errors.email?.type === 'required' ? 'Имејл је обавезно поље' : ''}</p>
                    <p>{errors.email?.type === 'pattern' ? 'Имејл адреса није исправно попуњена' : ''}</p>
                    <p>{error === 'error' && Object.keys(errors).length === 0 && 'Грешка при слању имејла верификације. Промена имејла може да се тражи једном у 3 минута.'}</p>
                    <p>{error === 'inUse' && Object.keys(errors).length === 0 && 'Имејл адреса је већ у употреби. Пробајте другу имејл адресу.'}</p>
                </span>
                <button
                    type="submit"
                    className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] max-w-[520px] cursor-pointer mt-[8px]"
                >
                    {!loading ? 'Измени имејл' : <Loading height={25} />}
                </button>
            </form>
        </div>
    )
}

export default EmailChangeStep1
