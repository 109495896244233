import { TblOblast3 } from '../../../models/Form3/Form3.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import UploadFile from '../../../components/UploadFile'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import { SelectYesNoComponent } from '../../../components/Common/SelectComponent'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step9({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast3>({ control })

    const fields: ('firstName' | 'lastName' | 'phoneNumber' | 'email')[] = ['firstName', 'lastName', 'phoneNumber', 'email']
    const additionalFields: ('education_date' | 'education_name' | 'education_org' | 'education_teacher')[] = [
        'education_date',
        'education_name',
        'education_org',
        'education_teacher',
    ]

    const hasEducation = watchedValues?.submitted_by?.has_education
    const disabledNext = !fields.every((e) => !!watchedValues?.submitted_by && !!watchedValues?.submitted_by[e])
    const disabledNextWithDisabledFields = !additionalFields.every((e) => !!watchedValues?.submitted_by && !!watchedValues.submitted_by[e])

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const goToNextStep = () => {
        if (adminView) {
            nextStep()
        } else if (hasEducation) {
            if (!disabledNext && !disabledNextWithDisabledFields) {
                nextStep()
            }
        } else {
            if (!disabledNext) {
                nextStep()
            }
        }
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error.email?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const checkDisabled = () => {
        return !watchedValues.submitted_by?.has_education || adminView
    }

    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">
                Подаци о лицу задуженом за родну равноправност код органа јавне власти који имају више од 50 запослених радно ангажованих лица (уколико постоје у бази за ово правно
                лице повући ће се аутоматски а корисник може да едитује)
            </h1>
            <div className="flex flex-row gap-2 w-full">
                <div className="mt-[20px] mb-[50px] flex flex-col gap-[15px] ml-[5px]">
                    <SimpleInputRowForObject
                        title="Име"
                        name="submitted_by.firstName"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        disabled={adminView}
                        isName
                    />
                    <SimpleInputRowForObject
                        title="Презиме"
                        name="submitted_by.lastName"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        disabled={adminView}
                        isName
                    />
                    <SimpleInputRowForObject
                        title="Контакт телефон"
                        name="submitted_by.phoneNumber"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        disabled={adminView}
                    />
                    <SimpleInputRowForObject
                        title="Емаил"
                        name="submitted_by.email"
                        maxNumberOfCharacter={300}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        disabled={adminView}
                        isEmail
                    />

                    <div className="h-[2px]">
                        {(errors.submitted_by?.first_name ||
                            errors.submitted_by?.last_name ||
                            errors.submitted_by?.phone ||
                            errors.submitted_by?.email ||
                            errors.submitted_by?.workplace_name) && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('submitted_by')}</p>}
                    </div>
                </div>
                <HelpButton name="o3-k9" marginTop={20} />
            </div>
            <h2 className="mt-[50px]">Прилагање одлуке о именовању у ПДФ формату</h2>
            <UploadFile control={control} name={'submitted_by.files'} disabled={adminView} />

            <div className="mb-[50px]">
                <p className="mt-[20px] mb-[20px]">Да ли је лице задужено за родну равноправност у години извештавања прошло стручну обуку из области родне равноправности?</p>
                {/* <RadioButtonsControl control={control} name={'submitted_by.has_education'} options={radioOptions} errors={errors} boolean /> */}
                <SelectYesNoComponent control={control} name="submitted_by.has_education" value={watchedValues.submitted_by?.has_education} disabled={adminView} />
            </div>

            <div className="flex flex-col gap-[15px]">
                <p className="mb-[5px]">Уколико је одговор да - када под којим називом, и ко је био организатор и реализатор поменуте обуке:</p>
                <div className="flex flex-row gap-[10px] h-[50px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Датум обуке</p>
                    </div>
                    <HiddenDatePicker
                        tabIndex={watchedValues.submitted_by?.has_education ? 0 : -1}
                        name={`submitted_by.education_date`}
                        value={watchedValues?.submitted_by?.education_date}
                        control={control}
                        errors={errors}
                        isRequired={checkDisabled() ? false : true}
                        lastStep
                        disabled={checkDisabled()}
                    />
                </div>
                <SimpleInputRowForObject
                    title="Назив обуке"
                    name="submitted_by.education_name"
                    maxNumberOfCharacter={200}
                    control={control}
                    disabled={checkDisabled()}
                    errors={errors}
                    isRequired={checkDisabled() ? false : true}
                />
                <SimpleInputRowForObject
                    title="Организатор обуке"
                    name="submitted_by.education_org"
                    maxNumberOfCharacter={200}
                    control={control}
                    disabled={checkDisabled()}
                    errors={errors}
                    isRequired={checkDisabled() ? false : true}
                />
                <SimpleInputRowForObject
                    title="Реализатор обуке"
                    name="submitted_by.education_teacher"
                    maxNumberOfCharacter={200}
                    control={control}
                    disabled={checkDisabled()}
                    errors={errors}
                    isRequired={checkDisabled() ? false : true}
                />
            </div>
            <div className="h-[2px]">
                {watchedValues.submitted_by?.has_education &&
                    (errors.submitted_by?.education_date ||
                        errors.submitted_by?.education_name ||
                        errors.submitted_by?.education_org ||
                        errors.submitted_by?.education_teacher) && <p className="text-[0.75rem] text-[#d32f2f] ml-[50px]">{renderErrorMessage('submitted_by')}</p>}
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step9
