import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import ComplexTable from '../../../components/Tables/ComplexTable'
import { StepInterfaceObl2 } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

interface RowInterface {
    label: string
    fields: (keyof TblOblast2)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o2_k2_z_15_30', 'o2_k2_m_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o2_k2_z_31_40', 'o2_k2_m_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o2_k2_z_41_50', 'o2_k2_m_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o2_k2_z_51_60', 'o2_k2_m_51_60'] },
    { label: 'Од 61-70 година живота и више', fields: ['o2_k2_z_61_70', 'o2_k2_m_61_70'] },
]

const allFields = [
    'o2_k2_m_15_30',
    'o2_k2_z_15_30',
    'o2_k2_m_31_40',
    'o2_k2_z_31_40',
    'o2_k2_m_41_50',
    'o2_k2_z_41_50',
    'o2_k2_m_51_60',
    'o2_k2_z_51_60',
    'o2_k2_m_61_70',
    'o2_k2_z_61_70',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]

const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Старосна структура свих чланова/чланица тела за родну равноправност</h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={adminView}
                />
                <HelpButton name="o2-k2" marginTop={30} />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
