import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/ComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../components/Tables/ComplexTable'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

interface RowInterface {
    label: string
    fields: (keyof TblOblast1)[]
}

const rows: RowInterface[] = [
    { label: '0 Лице без стеченог основног образовања и васпитања', fields: ['o1_k3_z_n0', 'o1_k3_m_n0'] },
    { label: 'Први ниво (ниво 1) НОКС', fields: ['o1_k3_z_n1', 'o1_k3_m_n1'] },
    { label: 'Други ниво (ниво 2) НОКС', fields: ['o1_k3_z_n2', 'o1_k3_m_n2'] },
    { label: 'Трећи ниво (ниво 3) НОКС', fields: ['o1_k3_z_n3', 'o1_k3_m_n3'] },
    { label: 'Четврти ниво (ниво 4) НОКС', fields: ['o1_k3_z_n4', 'o1_k3_m_n4'] },
    { label: 'Пети ниво (ниво 5) НОКС', fields: ['o1_k3_z_n5', 'o1_k3_m_n5'] },
    { label: 'Шести ниво, подниво један (ниво 6.1) и подниво два (ниво 6.2) НОКС', fields: ['o1_k3_z_n6', 'o1_k3_m_n6'] },
    { label: 'Седми ниво, подниво један (ниво 7.1) и подниво два (ниво 7.2) НОКС', fields: ['o1_k3_z_n7', 'o1_k3_m_n7'] },
    { label: 'Осми ниво (ниво 8) НОКС', fields: ['o1_k3_z_n8', 'o1_k3_m_n8'] },
]

const allFields = [
    'o1_k3_m_n0',
    'o1_k3_m_n1',
    'o1_k3_m_n2',
    'o1_k3_m_n3',
    'o1_k3_m_n4',
    'o1_k3_m_n5',
    'o1_k3_m_n6',
    'o1_k3_m_n7',
    'o1_k3_m_n8',
    'o1_k3_z_n0',
    'o1_k3_z_n1',
    'o1_k3_z_n2',
    'o1_k3_z_n3',
    'o1_k3_z_n4',
    'o1_k3_z_n5',
    'o1_k3_z_n6',
    'o1_k3_z_n7',
    'o1_k3_z_n8',
] as const

const allMaleFields = ['o1_k3_m_n0', 'o1_k3_m_n1', 'o1_k3_m_n2', 'o1_k3_m_n3', 'o1_k3_m_n4', 'o1_k3_m_n5', 'o1_k3_m_n6', 'o1_k3_m_n7', 'o1_k3_m_n8'] as const
const allFemaleFields = ['o1_k3_z_n0', 'o1_k3_z_n1', 'o1_k3_z_n2', 'o1_k3_z_n3', 'o1_k3_z_n4', 'o1_k3_z_n5', 'o1_k3_z_n6', 'o1_k3_z_n7', 'o1_k3_z_n8'] as const

type AllFieldsType = (typeof allFields)[number]

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name">3. Квалификације које поседују запослена и радно ангажована лица:</h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['Ниво квалификације:', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={adminView}
                />
                <HelpButton name="o1-k3" marginTop={30} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
