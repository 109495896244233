import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import SimpleTable from '../../../components/Tables/SimpleTable'
import { isNotEmpty } from '../../../utils/Validation'
import HelpButton from '../../../components/Common/HelpButton'

export interface StepInterface {
    control?: Control<TblOblast1>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k1_m' },
    { label: 'Жене', field: 'o1_k1_z' },
]

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: ('o1_k1_m' | 'o1_k1_z')[] = ['o1_k1_m', 'o1_k1_z']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">1. Број запослених и радно ангажованих лица:</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
                <HelpButton name="o1-k1" marginTop={30} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
