import { StepInterfaceObl2 } from './Step1'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import Textbox from '../../../components/Common/Textbox'
import ActionButtons from '../../../components/ActionButtons'
import PositiveNumberInputCell from '../Components/PositiveNumberInputCell'
import HelpButton from '../../../components/Common/HelpButton'

const Step17 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, clearErrors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('o2_k17_procenat' | 'o2_k17_obrazlozenje')[] = ['o2_k17_procenat', 'o2_k17_obrazlozenje']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext && !errors.o2_k17_procenat) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">Планирани степен реализације покрајинског или локалног буџета са становиштва остварења родне перспективе у години вођења евиденције</h1>
            <div className="mt-[20px] py-[15px] flex flex-row items-center min-h-[60px]">
                <p className="font-[14px] mr-[20px]">Проценат буџета</p>
                {/* <PositiveNumberInput name={'o2_k17_procenat' as string} control={control} defaultValue="" /> */}
                <div className="h-[52px]">
                    <div className="flex flex-row gap-5 ">
                        <PositiveNumberInputCell
                            name={'o2_k17_procenat'}
                            control={control}
                            decimal={true}
                            isMultipleTable={false}
                            errors={errors}
                            isRequired={!adminView}
                            precentage={true}
                            max={100}
                            clearErrors={clearErrors}
                            disabled={adminView}
                        />{' '}
                        <HelpButton name="o2-k17" />
                    </div>
                    <div>
                        {errors?.o2_k17_procenat?.type === 'max' && (
                            <p className="min-h-5 text-[#d32f2f] text-[0.75rem] text leading-5 ml-[50px]">Број не може да буде већи од 100</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">Образложење:</h4>
                <Textbox
                    name="o2_k17_obrazlozenje"
                    control={control}
                    placeholder="Текст (200 карактера)*"
                    maxNumberOfCharacters={200}
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step17
