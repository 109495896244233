import React from 'react'
import { StepInterfaceObl2 } from './Step1'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import SimpleInputRowForObject from '../Components/SimpleImputRowForObject'
import UploadFile from '../../../components/UploadFile'
import HelpButton from '../../../components/Common/HelpButton'

const Step20 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('firstName' | 'lastName' | 'phoneNumber' | 'email')[] = ['firstName', 'lastName', 'phoneNumber', 'email']
    const disabledNext = !fields.every((e) => !!watchedValues?.submitted_by && !!watchedValues?.submitted_by[e])

    const goToNextStep = () => {
        if (!disabledNext && !errors?.submitted_by?.email) nextStep()
        else if (adminView) nextStep()
    }

    const renderErrorMessage = (field: string) => {
        const error = errors?.[field]

        if (!error) return null

        let message = 'Попуните обавезно поље'

        if (error.email?.type === 'pattern') {
            message += ', Електронска адреса није исправно попуњена.'
        } else {
            message += '.'
        }

        return <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">{message}</p>
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    return (
        <div onKeyDown={handleKeyDown}>
            <h1 className="step-name">Подаци о СЕКРЕТАРУ/СЕКРЕТАРКИ ТЕЛА ЗА РОДНУ РАВНОПРАВНОСТ</h1>
            <div className="flex flex-row gap-2 w-full">
                <div className="mt-[20px] flex flex-col gap-[15px]">
                    <SimpleInputRowForObject
                        title="Име"
                        name="submitted_by.firstName"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        isName
                        disabled={adminView}
                    />
                    <SimpleInputRowForObject
                        title="Презиме"
                        name="submitted_by.lastName"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        isName
                        disabled={adminView}
                    />
                    <SimpleInputRowForObject
                        title="Контакт телефон"
                        name="submitted_by.phoneNumber"
                        maxNumberOfCharacter={50}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        disabled={adminView}
                    />
                    <SimpleInputRowForObject
                        title="Емаил"
                        name="submitted_by.email"
                        maxNumberOfCharacter={300}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        isEmail
                        disabled={adminView}
                    />
                </div>
                <HelpButton name="o2-k20" marginTop={20} />
            </div>
            <div className="h-[20px]">{Object.keys(errors).length > 0 && <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]"> {renderErrorMessage('submitted_by')}</p>}</div>
            <h2>Прилагање одлуке о именовању у ПДФ формату</h2>
            <UploadFile control={control} name="submitted_by.files" />

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step20
