import React from 'react'
import { Select, MenuItem } from '@mui/material'

interface CategorySelectorProps {
    categories: any[]
    selectedCategories: string[]
    onSelectCategory: (form: string) => void
    text: string
}

export const CategorySelector = ({ text, categories, onSelectCategory, selectedCategories }: CategorySelectorProps) => {
    return (
        <div className="flex flex-row gap-[10px] items-center">
            <label htmlFor="Odaberi kategoriju">{text}</label>
            <Select
                labelId="select-component"
                id="select-component"
                multiple
                value={selectedCategories}
                onChange={(e) => onSelectCategory(e.target.value as string)}
                className={`border-[#00000010] min-w-[186px] max-w-[186px] h-[50px] select-component-css`}
                sx={{
                    '.MuiOutlinedInput-notchedOutline border-[#00000010]': {
                        borderColor: '#00000010',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00000010',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00000010',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: '#0000008F !important',
                    },
                }}
                displayEmpty
            >
                {categories?.map((item) => (
                    <MenuItem key={item.code} value={item.code} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
