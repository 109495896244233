import React from 'react'
import { Control, FieldValues, useWatch } from 'react-hook-form'
import { getPercentage } from '../../utils/mathUtils'
import { Button } from '@mui/material'
import TextInputCell from '../Common/TextInputCell'
import PositiveNumberInputCell from '../../screens/Form2/Components/PositiveNumberInputCell'
import HelpButton from '../Common/HelpButton'

interface MultipleInputTableProps<T extends FieldValues> {
    fields: any[]
    control: Control<T> | undefined
    allFields: any[]
    header: string[]
    decimal?: boolean
    remove: any
    name: string
    errors?: any
    k10?: boolean
    disabled?: boolean
    showHelpMessage?: boolean
    helpName?: string
}

function MultipleInputTable<T extends FieldValues>({
    fields,
    control,
    header,
    decimal = false,
    remove,
    name,
    errors,
    k10 = false,
    disabled,
    showHelpMessage = false,
    helpName = '',
}: MultipleInputTableProps<T>) {
    const watchedValues = useWatch({ control }) as { [key: string]: any }

    let totalMaleValue = 0
    let totalFemaleValue = 0

    const activities = watchedValues[name]

    if (Array.isArray(activities)) {
        activities.forEach((activity: any) => {
            const maleNumber = Number(activity.maleNumber) || 0
            const femaleNumber = Number(activity.femaleNumber) || 0

            totalMaleValue += maleNumber
            totalFemaleValue += femaleNumber
        })
    }
    const totalValue = totalMaleValue + totalFemaleValue

    return (
        <table className="multiple-input-table mt-[20px]">
            <tr className="multiple-input-table-header">
                {header.map((head, index) => (
                    <th className={`multiple-input-table-cell ${index === 0 && 'min-w-[450px]'}`} key={index}>
                        {head}
                    </th>
                ))}
                {showHelpMessage && <HelpButton name={helpName} marginTop={0} />}
            </tr>
            {fields.map((field, index) => {
                const femaleValue = Number(watchedValues[name]?.[index]?.femaleNumber) || 0
                const maleValue = Number(watchedValues[name]?.[index]?.maleNumber) || 0
                const total = maleValue + femaleValue

                const femalePercentage = getPercentage(femaleValue, totalFemaleValue)
                const malePercentage = getPercentage(maleValue, totalMaleValue)

                return (
                    <div className="flex flex-row" key={index}>
                        <tr key={field.id} className="multiple-input-table-row">
                            <td className={`multiple-input-table-cell !border-none !min-w-[450px]`}>
                                <TextInputCell
                                    name={`${name}.${index}.level`}
                                    control={control}
                                    placeholder="Текст (100 карактера)*"
                                    errors={errors}
                                    isRequired={!disabled}
                                    maxNumberOfCharacter={100}
                                    height={62}
                                    isMultipleTable={true}
                                    disabled={disabled}
                                />
                            </td>
                            <td className="multiple-input-table-cell editable !border-none">
                                <PositiveNumberInputCell
                                    name={`${name}.${index}.femaleNumber`}
                                    control={control}
                                    decimal={decimal}
                                    isMultipleTable={true}
                                    errors={errors}
                                    isRequired={!disabled}
                                    height={62}
                                    isArray={true}
                                    disabled={disabled}
                                />
                            </td>
                            <td className="multiple-input-table-cell disabled">{femalePercentage}%</td>
                            <td className="multiple-input-table-cell editable !border-none">
                                <PositiveNumberInputCell
                                    name={`${name}.${index}.maleNumber`}
                                    control={control}
                                    decimal={decimal}
                                    isMultipleTable={true}
                                    errors={errors}
                                    isRequired={!disabled}
                                    height={62}
                                    isArray={true}
                                    disabled={disabled}
                                />
                            </td>
                            <td className="multiple-input-table-cell disabled">{malePercentage}%</td>
                            <td className="multiple-input-table-cell disabled">{total || 'Цео број'}</td>
                            {k10 && <td className="multiple-input-table-cell disabled">{getPercentage(total, totalValue)}%</td>}
                        </tr>
                        {fields.length >= 2 ? (
                            <div className="ml-[20px] flex justify-center items-center ">
                                <Button color="error" variant="contained" onClick={() => remove(index)} disabled={disabled}>
                                    Обриши
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )
            })}
            <tr className="multiple-input-table-footer">
                <td className="multiple-input-table-cell min-w-[450px]">Укупно</td>
                <td className="multiple-input-table-cell">{totalFemaleValue || 'Цео број'}</td>
                <td className="multiple-input-table-cell">{getPercentage(totalFemaleValue, totalFemaleValue)}%</td>
                <td className="multiple-input-table-cell">{totalMaleValue || 'Цео број'}</td>
                <td className="multiple-input-table-cell">{getPercentage(totalMaleValue, totalMaleValue)}%</td>
                <td className="multiple-input-table-cell">{totalValue || 'Цео број'}</td>
                {k10 && <td className="multiple-input-table-cell">{getPercentage(totalValue, totalValue)}%</td>}
            </tr>
            <div className="h-[2px]"> {errors[name] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default MultipleInputTable
