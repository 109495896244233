import { StepInterfaceObl2 } from './Step1'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import ActionButtons from '../../../components/ActionButtons'
import TextInput from '../../../components/Common/TextInput'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useUser } from '../../../auth/UseUser'
import HelpButton from '../../../components/Common/HelpButton'

const Step21 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, setValue }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: 'submitted_place'[] = ['submitted_place']
    const disabledNext = false // !watchedValues['submitted_place'] && !adminView

    const { user } = useUser()
    const city = user?.city

    useEffect(() => {
        if (setValue) setValue('submitted_place', city)
    }, [])

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name">Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља</h1>

            <div className="flex flex-row items-center mt-[100px] gap-[5px]">
                <p>У</p>
                <TextInput control={control} name="submitted_place" placeholder="Град" isName disabled={true} />
                <p>, дана {format(new Date(), 'dd.MM.yyyy.')}</p>
                <div className="ml-3">
                    <HelpButton name="o2-k21" />
                </div>
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={disabledNext} previousButtonDisabled={false} lastStep isAdmin={adminView} />
        </div>
    )
}

export default Step21
