import ActionButtons from '../../../../components/ActionButtons'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'

function Step19({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, setValue }: StepInterface) {
    return (
        <div>
            <h1 className="step-name">19. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља</h1>
            <NoAnalytics />
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={true} previousButtonDisabled={false} lastStep isAdmin={adminView} />
        </div>
    )
}

export default Step19
