import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import SimpleTable from '../../../../components/Tables/SimpleTable'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'

export interface StepInterface {
    control?: Control<TblOblast1>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    countType?: 'total' | 'average'
}
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k1_m' },
    { label: 'Жене', field: 'o1_k1_z' },
]

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, countType, setValue }: StepInterface) {
    const watchedValues = useWatch<TblOblast1>({ control })
    const fields: ('o1_k1_m' | 'o1_k1_z')[] = ['o1_k1_m', 'o1_k1_z']

    useEffect(() => {
        loadData()
    }, [countType])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">1. Број запослених и радно ангажованих лица:</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable
                    control={control}
                    header={['Пол', countType == 'total' ? 'Ukupan zbir' : 'Srednja vrednost', 'Проценат']}
                    rows={rows}
                    fields={fields}
                    errors={errors}
                    disabled={true}
                />
                <HelpButton name="o1-k1" marginTop={30} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
