import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Box, Button, Typography } from '@mui/material'

interface PositiveNumberInputInterface {
    name: string
    control: any
    defaultValue?: string
    decimal?: boolean
    isMultipleTable?: boolean
    height?: number
    borderColor?: string
    errors?: any
    isRequired?: any
    isArray?: any
    isStep5?: boolean
    precentage?: boolean
    max?: number
    maxW?: number
    disabled?: boolean
    clearErrors?: any
}

function PositiveNumberInputCell({
    name,
    control,
    defaultValue = '',
    decimal = false,
    isMultipleTable,
    errors = {},
    isRequired = false,
    height,
    borderColor = '#00000010',
    isArray = false,
    precentage = false,
    max,
    disabled = false,
    maxW = 75,
    clearErrors,
}: PositiveNumberInputInterface) {
    const [shownValue, setShowValue] = useState<string | undefined>(defaultValue)
    const regexString = decimal ? /^[\d,.]*$/ : /^[\d]*$/

    const parts = name.split('.')
    const arrayName = parts[0]
    const index = parseInt(parts[1], 10)
    const fieldName = parts[2]

    const getBorderClass = () => {
        if (disabled) {
            return 'border-[#00000010]'
        } else if ((!isArray && errors && errors[name]) || (!isArray && errors && errors[arrayName])) {
            return 'border-[#d32f2f]'
        } else if (errors && !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName]) {
            return 'border-[#d32f2f]'
        } else {
            return 'border-[#D9D9D9]'
        }
    }

    function getPlaceholder(decimal: boolean, percentage: boolean) {
        if (percentage) {
            return '0-100*'
        } else if (decimal) {
            return '0.00*'
        } else {
            return 'Цео број*'
        }
    }

    const getColor = () => {
        if (isArray) {
            return !!errors[arrayName] && !!errors[arrayName][index] && !!errors[arrayName][index][fieldName] ? '#d32f2f' : 'inherit'
        } else {
            return !!errors[arrayName] ? '#d32f2f' : 'inherit'
        }
    }

    return (
        <div className="flex flex-row h-full w-full">
            <div
                className={`border ${getBorderClass()} w-full flex justify-center items-center ${maxW ? `max-w-[${maxW}px] min-w-[${maxW}px] p-[1px]` : 'min-w-[100px] p-[15px]'}`}
                style={{ height: height ? height : '100%' }}
            >
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={{ required: isRequired, max: max }}
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                disabled={disabled}
                                onChange={(event) => {
                                    let newValue = event.target.value

                                    const decimalCount = (newValue.match(/\./g) || []).length
                                    if (decimalCount > 1) {
                                        return
                                    }

                                    if (newValue === '') {
                                        field.onChange(undefined)
                                        setShowValue(undefined)
                                        // clearErrors(name)
                                        return
                                    }

                                    if (newValue === '.') {
                                        newValue = '0.'
                                    }

                                    if (decimal && newValue.includes('.') && newValue.includes(',')) {
                                        return
                                    }

                                    if (decimal && newValue.includes(',')) {
                                        const updatedValue = newValue.replace(',', '.')
                                        field.onChange(parseFloat(updatedValue))
                                        setShowValue(updatedValue)
                                        return
                                    }

                                    if (precentage && regexString.test(newValue)) {
                                        const value = parseFloat(newValue)
                                        if (value >= 0 && value <= 100) {
                                            field.onChange(!!newValue ? value : null)
                                            clearErrors(name)
                                            setShowValue(newValue)
                                        }
                                    } else if (regexString.test(newValue)) {
                                        field.onChange(!!newValue ? parseFloat(newValue) : null)
                                        setShowValue(newValue)
                                    }
                                }}
                                onBlur={() => {
                                    if (shownValue && (decimal || precentage)) {
                                        let updatedValue = shownValue
                                        if (!shownValue.includes('.')) {
                                            updatedValue = `${shownValue}.00`
                                        } else {
                                            const parts = shownValue.split('.')
                                            updatedValue = `${parts[0]}.${parts[1].slice(0, 2).padEnd(2, '0')}`
                                        }
                                        setShowValue(updatedValue)
                                    }
                                }}
                                type="tel"
                                value={shownValue !== '' || undefined ? shownValue : field.value}
                                className="!bg-[transparent] border-none"
                                onKeyDown={(event) => {
                                    const allowedKeys = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.']

                                    if ((!allowedKeys.includes(event.key) && !regexString.test(event.key)) || event.key === ',') {
                                        event.preventDefault()
                                    }
                                }}
                                placeholder={getPlaceholder(decimal, precentage)}
                                style={{ fontSize: isMultipleTable ? '14px' : '' }}
                                sx={{
                                    textAlign: 'right',
                                    '& .MuiInputBase-input': {
                                        textAlign: 'right',
                                        padding: '12px',
                                        color: getColor(),
                                        '&::placeholder': {
                                            color: getColor(),
                                        },
                                    },
                                    '&.MuiInputBase-root:before': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:after': {
                                        borderBottom: 'none',
                                    },
                                    '&.MuiInputBase-root:hover:not(.Mui-disabled):before': {
                                        borderBottom: 'none',
                                    },
                                }}
                            />
                        </>
                    )}
                />
            </div>
            {precentage && (
                <Typography variant="body1" style={{ color: getColor(), marginLeft: '8px', marginTop: '12px', fontSize: '18px' }}>
                    %
                </Typography>
            )}
        </div>
    )
}

export default PositiveNumberInputCell
