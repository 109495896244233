import ActionButtons from '../../../../components/ActionButtons'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'

function Step18({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    return (
        <div>
            <h1 className="step-name">
                18. Подаци о лицу задуженом за родну равноправност код органа јавне власти који имају више од 50 запослених и јавно ангажованих лица (уколико постоје у бази за ово
                правно лице повући ће се аутоматски а корисник може да едитује):
            </h1>

            <NoAnalytics />
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step18
