import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useUser } from '../../auth/UseUser'
import { getAllCities, registerStep2, updateUser, whoAmI } from '../../server/server'
import { VirtualizedAutocomplete } from '../../components/Common/VirtualizedAutocomplete'
import HelpButton from '../../components/Common/HelpButton'

export interface RegisterStepInterface {
    handleSubmit: any
    onSubmit: any
    errors: any
    register: any
    control?: any
    setValue?: any
    error?: any
    setError?: any
}
export type CityDto = {
    id?: string
    name: string
    zip: number
    region: string
    municipality?: string
}

export type RegisterStep2Inputs = {
    pib: number
    mb: number
    subjectName: string
    firstName: string
    lastName: string
    address: string
    phoneNumber: string
    city: CityDto
    cb1: boolean
    cb2: boolean
}

const UserProfile = () => {
    const {
        setValue,
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors },
    } = useForm<RegisterStep2Inputs>()
    const { user, setUser } = useUser()
    const navigate = useNavigate()
    const fields: { label: string; field: keyof RegisterStep2Inputs }[] = [
        { label: 'ПИБ', field: 'pib' },
        { label: 'Матични број', field: 'mb' },
        { label: 'Назив субјекта', field: 'subjectName' },
        { label: 'Име овлашћеног лица', field: 'firstName' },
        { label: 'Презиме овлашћеног лица', field: 'lastName' },
        { label: 'Адреса', field: 'address' },
        { label: 'Број телефона', field: 'phoneNumber' },
        // { label: 'Категорија', field: 'category' },
    ]
    const [cities, setCities] = useState<any>()

    useEffect(() => {
        getCities()
    }, [])

    useEffect(() => {
        if (user && cities?.length > 0) {
            setUserData()
        }
    }, [user, cities])

    const getCities = async () => {
        const resp = await getAllCities()
        if (resp.status === 200) {
            setCities(resp.data)
        }
    }

    const setUserData = () => {
        setValue('pib', user?.pib || '')
        setValue('mb', user?.mb || '')
        setValue('subjectName', user?.subjectName || '')
        setValue('firstName', user?.firstName || '')
        setValue('lastName', user?.lastName || '')
        setValue('address', user?.address || '')
        setValue('phoneNumber', user?.phoneNumber || '')

        const userCity = cities.find((city: any) => city.name === user.city)
        if (userCity) {
            setValue('city', userCity)
        }
    }

    const onSubmit: SubmitHandler<RegisterStep2Inputs> = async () => {
        nextStep()
    }

    const nextStep = async () => {
        const data = {
            pib: Number(getValues('pib')),
            mb: Number(getValues('mb')),
            subjectName: getValues('subjectName'),
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            address: getValues('address'),
            phoneNumber: getValues('phoneNumber'),
            email: user.email,
            city: getValues('city'),
        }

        const resp = await updateUser(data)
        if (resp.status == 201) {
            const whoResp = await whoAmI()
            const user = whoResp.data
            if (whoResp.status === 200) {
                setUser(user)
                alert('Профил успешно измењен.')
                navigate('/')
            }
        } else {
            alert('Greška pri izmeni profila')
        }
    }

    const getValidationPattern = (field: any, label: any) => {
        switch (field) {
            case 'pib':
                return {
                    value: /^[0-9]{9}$/,
                    message: `${label} мора садржати тачно 9 цифара.`,
                }
            case 'mb':
                return {
                    value: /^[0-9]{8}$/,
                    message: `${label} мора садржати тачно 8 цифара.`,
                }
            case 'firstName':
            case 'lastName':
                return {
                    value: /^[A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ]+$/,
                    message: `${label} може садржати само слова`,
                }
            default:
                return undefined
        }
    }

    const preventNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (/[^A-Za-zА-Ша-шЉљЊњЏџЂђЈјЋћ]/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }

    const preventLetters = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (!/^[0-9]$/.test(key) && key !== 'Backspace' && key !== 'Tab' && key !== 'Enter') {
            event.preventDefault()
        }
    }
    const preventInvalidPhoneInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = ['/', '-', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight']
        const isNumber = /^\d$/.test(event.key)
        const isAllowedKey = allowedKeys.includes(event.key)

        if (!isNumber && !isAllowedKey) {
            event.preventDefault()
        }
    }

    return (
        <div className="items-center justify-center flex mb-[50px] min-w-[550px]">
            <div className="pl-[121px] pr-[45px] pb-[156px] pt-[20px] bg-[#ffffff] items-start flex flex-col ">
                <h2 className="mb-[32px] text-[24px] leading-[28px] font-light max-w-[520px] min-w-[500px] text-center">Профил корисника</h2>
                <form className="flex flex-col items-center w-full" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="w-full flex flex-col">
                        {fields.map((item, index) => (
                            <div key={index} className="flex flex-col mb-[16px]">
                                <div className="flex flex-row items-end justify-between">
                                    <label>{item.label}</label>
                                    {index === 0 && (
                                        <div className="mb-[5px]">
                                            <HelpButton name="user_profile_help" />
                                        </div>
                                    )}
                                </div>
                                <input
                                    className="border border-[#0000003B] max-w-[520px] min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder={item.label}
                                    {...register(item.field, {
                                        required: true,
                                        pattern: getValidationPattern(item.field, item.label),
                                    })}
                                    maxLength={item.field === 'pib' ? 9 : item.field === 'mb' ? 8 : undefined}
                                    onKeyDown={(event) => {
                                        if (item.field === 'firstName' || item.field === 'lastName') {
                                            preventNumbers(event)
                                        } else if (item.field === 'pib' || item.field === 'mb') {
                                            preventLetters(event)
                                        } else if (item.field === 'phoneNumber') {
                                            preventInvalidPhoneInput(event)
                                        }
                                    }}
                                />
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">
                                    {errors[item.field]?.type === 'required' ? `${item.label} је обавезно поље` : ''}
                                    {errors[item.field]?.type === 'pattern' ? `Молимо унесите исправан формат: ${errors[item.field]?.message} ` : ''}
                                </span>
                            </div>
                        ))}
                        <div>
                            <label>Седиште</label>
                            <Controller
                                name="city"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <VirtualizedAutocomplete
                                        items={cities}
                                        label={'Седиште'}
                                        onChange={(newValue: any) => {
                                            onChange(newValue)
                                        }}
                                        cityValue={value}
                                        disabled={false}
                                    />
                                )}
                            />
                        </div>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">{errors.city?.type === 'required' ? `Град је обавезно поље` : ''}</span>
                        {/* <div className="flex flex-col mb-[16px] max-w-[603px]">
                        <div className="flex flex-row gap-[23px] items-center">
                            <SelectBody
                                control={control}
                                isRequired={true}
                                name="category"
                                items={['Kategorija 1', 'Kategorija 2', 'Kategorija 3']}
                                errors={errors}
                                value={watchedValues.category}
                                minWidth={520}
                            ></SelectBody>
                            <InfoSVG />
                        </div>

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[14px]">{errors?.category?.type === 'required' ? `Категорија је обавезно поље` : ''}</span>
                    </div> */}

                        <button
                            type="submit"
                            className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer mt-[43px] max-w-[520px]"
                        >
                            Измени профил
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UserProfile
