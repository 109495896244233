export const FORM2_NAMES = [
    '01. Број запослених и радно ангажованих људи',
    '02. Старосна структура свих чланова/чланица тела за родну равноправност',
    '03. Квалификације које поседују лица именована у састав тела за родну равноправност, разврстана по полу',
    '04. Руководећег и извршног састава тела за родну равноправност, разврстаних по полу',
    '05. Уколико чланови/чланице или руководећи и извршни састав тела за родну равноправност по том основу остварује право на плату или другу накнаду, унети тражене податке исказане у просечном месечном номиналном износу, разврстане по полу, у назначеним категоријама  и друге накнаде запослених и радно ангажованих лица исказане у  просечном номиналном износу разврстане по полу у категоријама за извршилачка радна места и за положаје',
    '06. Броја и процента лица која су у години вођења евиденције више ангажована у реализацији активности у организацији тела за родну равноправност, разврстаних по полу и старосној доби',
    '07. Броја и процента лица која су у години вођења евиденције мање ангажована у реализацији активности у организацији тела за родну равноправност, разврстаних по полу и старосној доби',
    '08. Полазне оцена стањa у области родне равноправности, у години вођења евиденције',
    '09. Број иницираних и предложених мера за унапређење родне равноправности од стране тела за родну  равноправност у години вођења евиденције',
    '10. Број укључених особа оба пола у активности тела за родну равноправност везаних за промоцију родне равноправности, спречавање и сузбијање родно заснованог насиља и насиља према женама, као и борбу против дискриминације на основу пола, односно рода (у областима: рада, запошљавања, самозапошљавања, социјалног осигурања, социјалне и здравствене заштите, образовања, одбране и безбедности, саобраћаја, енергетике и комуникација, заштите животне средине, културе, јавног информисања, спорта, политичког деловања и јавних послова, репродуктивних и сексуалних права и приступу роби и услугама) у години вођења евиденције',
    '11. Број укључености особа оба пола у активности тела за родну равноправност везаних за Број и степен укључености организација цивилног друштва (удружења) у активности тела за родну равноправност везаних за промоцију родне равноправности, спречавање и сузбијање родно заснованог насиља и насиља према женама, као и борбу против дискриминације на основу пола, односно рода (у областима: рада, запошљавања, самозапошљавања, социјалног осигурања, социјалне и здравствене заштите, образовања, одбране и безбедности, саобраћаја, енергетике и комуникација, заштите животне средине, културе, јавног информисања, спорта, политичког деловања и јавних послова, репродуктивних и сексуалних права и приступу роби и услугама) у години вођења евиденцијe',
    '12. Број примљених пријава у телу за родну равноправност поднетих од стране грађана и грађанки о изложености узнемиравању, сексуалном узнемиравању, уцењивању, насиљу или неком другом поступању које има за последицу дискриминацију на основу пола, односно рода, разврстаних по полу и старосној доби подносиоца пријаве',
    '13. Број тражилаца и корисника бесплатне правне помоћи у сврху остваривања родне равноправности у години вођења евиденције, разврстаних по полу у следећем формату:',
    '14. Број и назив активности тела за родну равноправност реализованих у вези случајева насиља по основу пола, односно рода, у години вођења евиденције',
    '15. Назив развојних планова и других аката',
    '16. Назив програмских активности и пројеката',
    '17. Планирани степен реализације покрајинског или локалног буџета са становиштва остварења родне перспективе у години вођења евиденције',
    '18. Подаци о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину вођења евиденције, разврстани по полу',
    '19. Планиране активности сарадње тела за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном новоу по питању остваривања и унапређивања родне равноправност',
    '20. Подаци о СЕКРЕТАРУ/СЕКРЕТАРКИ ТЕЛА ЗА РОДНУ РАВНОПРАВНОСТ',
    '21. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
