import { TblOblast3 } from '../../../models/Form3/Form3.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast3>({ control })

    const fields: 'o3_k2_ocena'[] = ['o3_k2_ocena']
    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!(watchedValues[e] !== undefined) : false))

    const radioOptions = ['Одлично', 'Врло добро', 'Задовољава', 'Недовољно']

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name mb-[10px]">
                Унети кратку оцену стања у вези са положајем жена и мушкараца у органу јавне власти и послодавца, укључујући и списак посебних мера, разлоге за одређивање посебних
                мера и циљеве који се њима постижу, почетак примене, начин спровођења и контроле и престанак спровођења посебних мера:
            </h1>
            <div className=" flex flex-row gap-2">
                <RadioButtonsControl control={control} name={'o3_k2_ocena'} options={radioOptions} errors={errors} enumerated disabled={adminView} />
                <HelpButton name="o3-k2" marginTop={10} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
