import { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { TblOblast5, YESNO } from '../../../models/Form5/Form5.entity'
import ActionButtons from '../../../components/ActionButtons'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControl'
import { StepInterface } from './Step1'

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, setValue, isParty, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })
    const fields: ('o5_k5_r1' | 'o5_k5_razlog')[] = ['o5_k5_r1', 'o5_k5_razlog']

    useEffect(() => {
        if (watchedValues['o5_k5_r1'] === YESNO.Ne) setValue('o5_k5_razlog', '', { shouldValidate: true })
    }, [watchedValues['o5_k5_r1']])

    const disableTextLink = () => {
        if (watchedValues['o5_k5_r1'] === YESNO.Da) return false
        else return true
    }

    const disabledNext = !watchedValues['o5_k5_r1'] || (watchedValues['o5_k5_r1'] === YESNO.Da && !watchedValues['o5_k5_razlog'])

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <div className="mt-[35px]">
                <RadioButtonsControl
                    control={control}
                    name={fields[0]}
                    options={[YESNO.Da, YESNO.Ne]}
                    errors={errors}
                    label={
                        isParty
                            ? 'Да ли је чланство у вашој политичкој странци искључиво или превасходно намењено лицима, групама лица истог пола или рода из оправданих разлога и којих?'
                            : 'Да ли је чланство у вашој синдикалној организацији искључиво или превасходно намењено лицима, групама лица истог пола или рода из оправданих разлога и којих?'
                    }
                    isDisabled={adminView}
                    showHelpMessage
                    helpName="o5-k4"
                />
            </div>
            <div className="mt-[30px]">
                <h4 className="mb-[10px] text-[18px] leading-6 text-textPrimary">Уколико је одговор на претходно питање "Да", унесите разлоге</h4>
                <Textbox
                    name="o5_k5_razlog"
                    disabled={disableTextLink()}
                    control={control}
                    placeholder="Текст (200 карактера)"
                    maxNumberOfCharacters={200}
                    errors={errors}
                    isRequired={disableTextLink() ? false : 'Попуните обавезно поље.'}
                />
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
