import React, { useEffect, useRef, useState } from 'react'
import { getAllHelp } from '../../server/server'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import { ReactComponent as Edit } from '../../assets/svg/Edit.svg'
import HelpButton from '../../components/Common/HelpButton'
import EditHelpMessageModal from './EditHelpMessageModal'
import Loading from '../../components/Common/LoadingSpinner'
import { formatCyrillicDateWithCapitalizedMonth } from '../../utils/getDate'

interface HelpRow {
    id: number
    name: string
    title: string
    text: string
    created: string
}

const AdminAllHelp = () => {
    const [rows, setRows] = useState<HelpRow[]>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [total, setTotal] = useState(0)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [selectedHelpMessage, setSelectedHelpMessage] = useState<HelpRow | null>(null)
    const [loading, setLoading] = useState(false)
    const timeout = useRef<any>(null)

    useEffect(() => {
        loadHelp()
    }, [page, rowsPerPage])

    const loadHelp = async (text = searchTerm) => {
        setLoading(true)
        const resp = await getAllHelp(rowsPerPage, page * rowsPerPage, text)
        if (resp.status === 200) {
            setRows(resp.data.messages)
            setTotal(resp.data.total)
            //filterAndCopyToClipboard(resp.data.messages)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    async function filterAndCopyToClipboard(data: any) {
        const filteredData = data.map((data: any) => ({ name: data?.name, title: data?.title, text: data?.text }))
        const jsonData = JSON.stringify(filteredData, null, 2)
        try {
            await navigator.clipboard.writeText(jsonData)
        } catch (err) {
            alert(`Failed to copy data to clipboard: ${err}`)
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const openEditModal = (u: HelpRow) => {
        setSelectedHelpMessage(u)
        setEditModalOpen(true)
    }
    const closeEditModal = () => {
        setSelectedHelpMessage(null)
        setEditModalOpen(false)
    }

    const handleChange = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setSearchTerm(e.target.value)
        if (timeout !== null) {
            clearTimeout(timeout.current)
        }
        timeout.current = setTimeout(() => {
            if (page === 0) loadHelp(e.target.value)
            else setPage(0)
        }, 700)
    }

    return (
        <div className="!w-full px-[100px] bg-[#ffffff] py-[52px] h-full">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, mb: 2 }}>
                <h2 className="text-[24px] font-light max-w-[800px]">Управљање упутствима</h2>
                <HelpButton name="admin_help" />
            </Box>

            <div className="flex flex-row  justify-between mb-2">
                <TextField label="Претрага упутстава" variant="outlined" value={searchTerm} onChange={(e) => handleChange(e)} style={{ width: 350 }} />
            </div>

            {!loading && (
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="border border-[#E0E0E0]">Назив</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">Наслов упутства</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">Креирано</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">Измене</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows?.map((u) => (
                                    <TableRow key={u?.id}>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }} width={200}>
                                            {u?.name}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                            {u?.title}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                            {formatCyrillicDateWithCapitalizedMonth(u?.created)}
                                        </TableCell>
                                        <TableCell className="border border-[#E0E0E0] flex items-center justify-center" sx={{ paddingY: '10px' }} width={20}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    openEditModal(u)
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={total}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50]}
                        labelRowsPerPage="Број редова по страници"
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} од ${count}`}
                    />
                </Paper>
            )}
            {loading && (
                <div className="flex-1 h-full flex items-center justify-center">
                    <Loading />
                </div>
            )}

            <EditHelpMessageModal modalIsOpen={editModalOpen} closeModal={closeEditModal} helpContent={selectedHelpMessage} updateList={loadHelp} />
        </div>
    )
}

export default AdminAllHelp
