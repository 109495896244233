import React from 'react'
import Table from '@mui/material/Table'
import { Control, useWatch, FieldValues } from 'react-hook-form'
import PositiveNumberInput from '../Common/PositiveNumberInput'
import { getPercentage, sumValues } from '../../utils/mathUtils'
import '../../assets/styles/SimpleTable.css'
import PositiveNumberInputCell from '../../screens/Form2/Components/PositiveNumberInputCell'

interface RowInterface<T extends FieldValues> {
    label: string
    field: keyof T
}

interface ComplexTableInterface<T extends FieldValues> {
    rows: RowInterface<T>[]
    control: Control<T> | undefined
    fields: (keyof T)[]
    header: string[]
    decimal?: boolean
    errors?: any
    disabled?: boolean
}

function SimpleTable<T extends FieldValues>({ rows, control, header, fields, decimal = false, errors, disabled }: ComplexTableInterface<T>) {
    const watchedValues = useWatch({ control }) as T
    const totalSum = sumValues(watchedValues, fields as (keyof T)[])

    const hasRowErrors = fields.some((row) => errors?.[row])

    return (
        <table className="simple-table mt-[30px]">
            <thead>
                <tr className="simple-table-header">
                    {header.map((head, index) => (
                        <th className="simple-table-cell" key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr className="simple-table-row" key={index}>
                        <td className="simple-table-cell disabled  !mt-[8px]">{row.label}</td>
                        <td className="simple-table-cell editable !border-none  !mt-[8px]" style={{ border: 0, backgroundColor: '', padding: 0 }}>
                            {/* <PositiveNumberInput name={row.field as string} control={control} defaultValue="" /> */}
                            <PositiveNumberInputCell
                                name={row.field as string}
                                control={control}
                                decimal={decimal}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="simple-table-cell disabled  !mt-[8px]">{getPercentage(watchedValues[row.field], totalSum)}%</td>
                    </tr>
                ))}
                <tr className="simple-table-footer !mt-[8px]">
                    <td className="simple-table-cell">Укупно</td>
                    <td className="simple-table-cell">{decimal ? totalSum.toFixed(2) : totalSum}</td>
                    <td className="simple-table-cell">{getPercentage(totalSum, totalSum)}%</td>
                </tr>
            </tbody>
            <div className="h-[20px]">{hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default SimpleTable
