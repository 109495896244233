import { Button, MenuItem, Modal, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseFilled } from '../../../assets/svg/CloseFilled.svg'
import { adminAssignUserCategory, getChildCategories, getParentCategories } from '../../../server/server'

interface modalInterface {
    isOpen: boolean
    onClose: any
    currentCategory: CategoryInterface | undefined
    currentSubCategory: CategoryInterface | undefined
    userId: any
}

interface CategoryInterface {
    id: string
    name: string
}
export const EditCategoryModal = ({ isOpen, onClose, currentCategory, currentSubCategory, userId }: modalInterface) => {
    const [selectedCategory, setSelectedCategory] = useState<CategoryInterface | undefined>(currentCategory)
    const [selectedSubCategory, setSelectedSubCategory] = useState<CategoryInterface | undefined>(currentSubCategory)

    const [categories, setCategories] = useState<CategoryInterface[]>([])
    const [subCategories, setSubCategories] = useState<CategoryInterface[]>()

    useEffect(() => {
        fetchCategories()

        if (currentCategory?.id) {
            fetchSubCategories(currentCategory?.id)
        }
    }, [])

    const fetchCategories = async () => {
        const resp = await getParentCategories()

        if (resp.status === 200) {
            setCategories(resp.data)
        }
    }

    const fetchSubCategories = async (categoryId: string | undefined) => {
        if (!categoryId) return
        const resp = await getChildCategories(categoryId)
        if (resp.status === 200) {
            setSubCategories(resp.data)
        }
    }

    const handleChangeCategory = async () => {
        const data = {
            categoryId: selectedCategory?.id,
            subCategoryId: selectedSubCategory?.id || null,
            userId,
        }

        const resp = await adminAssignUserCategory(data)

        if (resp.status === 201) {
            alert('Успешно измењене категорије')
            onClose()
        } else {
            alert('Грешка при измени категорије')
        }
    }

    if (!isOpen) return null

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="flex flex-col justify-center items-center absolute top-1/2 left-1/2 min-w-[50%] max-w-[80%] transform -translate-x-1/2 -translate-y-1/2 bg-white p-5 shadow-lg rounded-lg">
                <h2 className="text-[18px]">Измена категорије</h2>

                <p className="mt-[20px]">Тренутна категорија</p>
                <div className="flex flex-col mt-[20px]">
                    <Select
                        value={selectedCategory?.id}
                        className={`border-[#00000010] min-w-[186px] select-component-css`}
                        sx={{
                            '.MuiSvgIcon-root ': {
                                fill: '#0000008F !important',
                            },
                        }}
                        onChange={(e) => {
                            const category = categories.find((cat) => cat.id === e.target.value)

                            setSelectedCategory(category)
                            setSelectedSubCategory(undefined)
                            setSubCategories(undefined)
                            fetchSubCategories(category?.id)
                        }}
                    >
                        {categories?.map((item) => (
                            <MenuItem key={item.id} value={item.id} style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <p className="mt-[20px]">Тренутна подкатегорија</p>
                <div className="flex flex-col mt-[20px] max-w-[70%]">
                    <Select
                        value={selectedSubCategory?.id}
                        className={`border-[#00000010] min-w-[186px] select-component-css`}
                        sx={{
                            '.MuiSvgIcon-root ': {
                                fill: '#0000008F !important',
                            },
                        }}
                        onChange={(e) => {
                            const subCategory = subCategories?.find((cat) => cat.id === e.target.value)
                            setSelectedSubCategory(subCategory)
                        }}
                    >
                        {subCategories && subCategories.length > 0 ? (
                            subCategories.map((item) => (
                                <MenuItem key={item.id} value={item.id} style={{ textAlign: 'center', height: 50, justifyContent: 'center', textWrap: 'wrap' }}>
                                    {item.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled style={{ textAlign: 'center', height: 50, justifyContent: 'center' }}>
                                Нема подкатегорија
                            </MenuItem>
                        )}
                    </Select>
                </div>
                <div className="mt-[40px] flex flex-row justify-between w-[80%] gap-[10px]">
                    <Button
                        onClick={() => onClose()}
                        style={{
                            width: '100%',
                            height: '42px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #243964',
                            color: '#243964',
                            fontSize: '15px',
                            fontWeight: '500',
                            lineHeight: '26px',
                        }}
                    >
                        Одустани
                    </Button>
                    <Button
                        onClick={() => handleChangeCategory()}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border !bg-[#243964] text-[15px] !text-white font-medium leading-[26px]"
                    >
                        Потврди измене
                    </Button>
                </div>
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
            </div>
        </Modal>
    )
}
