import React, { useEffect, useState } from 'react'
import { deleteUser, getUsers, sendResetPasswordEmail } from '../../server/server'
import { Button, MenuItem, MenuProps, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { YesNoModal } from '../../components/Common/YesNoModal'
import SortArrow from './Components/SortArrow'
import HelpButton from '../../components/Common/HelpButton'

type SearchType = {
    name: string
    email: string
    city: string
    phone: string
    category: string
    role: number
    subCategory: string
    pib: string
    valid: number
    sortField: string
    sortOrder: string
    mb: string
}

const textFieldStyles = {
    borderRadius: 0,
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
    },
    '.MuiSvgIcon-root': {
        fill: '#e0e0e0 !important',
    },
}

const UserManagementScreen = () => {
    const [users, setUsers] = useState<any[]>()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<any>()
    const [roleSelectOpen, setRoleSelectOpen] = useState(false)
    const [verifiedSelectOpen, setVerifiedSelectOpen] = useState(false)
    const [total, setTotal] = useState()
    const [showBackToTop, setShowBackToTop] = useState(false)

    const [searchTerm, setSearchTerm] = useState<SearchType>({
        name: '',
        email: '',
        city: '',
        phone: '',
        category: '',
        role: 0,
        subCategory: '',
        pib: '',
        valid: 0,
        sortField: 'name',
        sortOrder: 'ASC',
        mb: '',
    })
    const navigate = useNavigate()
    useEffect(() => {
        loadUsers()
    }, [page, rowsPerPage, searchTerm])

    const loadUsers = async () => {
        const resp = await getUsers(rowsPerPage, page * rowsPerPage, searchTerm)
        if (resp.status === 201) {
            setUsers(resp.data.data)
            setTotal(resp.data.totalCount)
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
        scrollToTop()
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    const handleConfirm = async (resp: any) => {
        if (resp === 'yes') {
            const resp = await deleteUser(selectedUser)
            if (resp.status === 200) {
                alert('Успешно обрисан корисник')
                loadUsers()
            } else {
                alert(`Грешка при брисању корисника, ${resp.status}`)
            }
        } else {
            return
        }
        handleCloseModal()
    }

    const handleSendResetPassword = async (email: string) => {
        const resp = await sendResetPasswordEmail(email)

        if (resp.status === 201) {
            alert('Успешно послат захтев за опоравак лозинке')
        } else if (resp.status === 500) {
            alert('Захтев за опоравак лозинке може да се пошаље једном у 3 минута.')
        } else {
            alert('Емаил адреса не постоји')
        }
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setSearchTerm((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleSort = (field: any) => {
        setSearchTerm((prev) => {
            const newSortOrder = prev.sortField === field && prev.sortOrder === 'ASC' ? 'DESC' : 'ASC'
            return { ...prev, sortField: field, sortOrder: newSortOrder }
        })
        loadUsers()
    }

    useEffect(() => {
        const handleScroll = () => {
            setShowBackToTop(window.scrollY > 200)
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const handleMenuMouseLeave = () => {
        setRoleSelectOpen(false)
        setVerifiedSelectOpen(false)
    }

    const customMenuProps: Partial<MenuProps> = {
        MenuListProps: {
            onMouseLeave: handleMenuMouseLeave,
        },
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <div className="!w-full px-[100px] py-[52px] min-h-full">
            <div className="flex flex-row items-center  gap-5 mb-4">
                <div className="flex flex-row items-center justify-between gap-8  flex-1">
                    <h2 className="text-[24px] font-light max-w-[800px]">Управљање корисницима</h2>
                    <HelpButton name="user_management_help" />
                </div>
                <Button variant="contained" style={{ backgroundColor: '#243964', color: 'white' }} onClick={() => navigate('/admin/user')}>
                    Додај новог админ корисника
                </Button>
            </div>
            <Paper>
                <TableContainer>
                    <Table className="overflow-x-auto w-full">
                        <TableHead>
                            <TableRow>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('name')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[200px]">
                                        <p>Назив корисника</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="name"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'name'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('email')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[220px]">
                                        <p>Имејл</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="email"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'email'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('city')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[100px]">
                                        <p>Место</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="city"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'city'}
                                        />
                                    </div>
                                </TableCell>

                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('admin')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[200px]">
                                        <p>Улога</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="admin"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'admin'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('category')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[150px]">
                                        <p>Категорија</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="category"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'category'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('subCategory')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[150px]">
                                        <p>Подкатегорија</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="subCategory"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'subCategory'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]">Детаљи</TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('pib')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[100px]">
                                        <p>ПИБ</p>
                                        <SortArrow sortField={searchTerm.sortField} currentField="pib" sortOrder={searchTerm.sortOrder} isActive={searchTerm.sortField === 'pib'} />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('mb')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer min-w-[100px]">
                                        <p>МБ</p>
                                        <SortArrow sortField={searchTerm.sortField} currentField="mb" sortOrder={searchTerm.sortOrder} isActive={searchTerm.sortField === 'mb'} />
                                    </div>
                                </TableCell>
                                <TableCell className="min-w-[220px] border border-[#E0E0E0]">Опоравак лозинке</TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('valid')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer">
                                        <p>Верификован</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="valid"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'valid'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]" onClick={() => handleSort('phone')}>
                                    <div className="flex flex-row items-center justify-between gap-2 cursor-pointer">
                                        <p>Телефон</p>
                                        <SortArrow
                                            sortField={searchTerm.sortField}
                                            currentField="phone"
                                            sortOrder={searchTerm.sortOrder}
                                            isActive={searchTerm.sortField === 'phone'}
                                        />
                                    </div>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0]">Деактивирај</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <TextField
                                        placeholder="Претрага по називу"
                                        type="text"
                                        sx={{ ...textFieldStyles }}
                                        name="name"
                                        value={searchTerm.name}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <TextField placeholder="Претрага по имејлу" sx={{ ...textFieldStyles }} name="email" value={searchTerm.email} onChange={handleSearchChange} />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[200px] !p-0">
                                    <TextField placeholder="Претрага по месту" sx={{ ...textFieldStyles }} name="city" value={searchTerm.city} onChange={handleSearchChange} />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <Select
                                        className="w-full"
                                        value={searchTerm.role}
                                        open={roleSelectOpen}
                                        onOpen={() => setRoleSelectOpen(true)}
                                        onClose={() => setRoleSelectOpen(false)}
                                        MenuProps={customMenuProps}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value as string, 10)
                                            setSearchTerm((prevTerm) => ({ ...prevTerm, role: value }))
                                            setRoleSelectOpen(false)
                                        }}
                                        sx={{
                                            borderRadius: 0,
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: '#0000008F !important',
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>-</MenuItem>
                                        <MenuItem value={1}>Администратор</MenuItem>
                                        <MenuItem value={2}>Корисник</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[220px] !p-0">
                                    <TextField
                                        placeholder="Претрага по категорији"
                                        sx={{ ...textFieldStyles }}
                                        name="category"
                                        className="w-full"
                                        value={searchTerm.category}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[240px] !p-0">
                                    <TextField
                                        placeholder="Претрага по подкатегорији"
                                        sx={{ ...textFieldStyles }}
                                        className="w-full"
                                        name="subCategory"
                                        value={searchTerm.subCategory}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0"></TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[180px] !p-0">
                                    <TextField
                                        placeholder="Претрага по ПИБ-у"
                                        sx={{ ...textFieldStyles }}
                                        className="w-full"
                                        name="pib"
                                        value={searchTerm.pib}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[180px] !p-0">
                                    <TextField
                                        placeholder="Претрага по МБ-у"
                                        sx={{ ...textFieldStyles }}
                                        className="w-full"
                                        name="mb"
                                        value={searchTerm.mb}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0"></TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0">
                                    <Select
                                        className="w-full"
                                        value={searchTerm.valid}
                                        open={verifiedSelectOpen}
                                        onOpen={() => setVerifiedSelectOpen(true)}
                                        onClose={() => setVerifiedSelectOpen(false)}
                                        MenuProps={customMenuProps}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value as string, 10)
                                            setSearchTerm((prevTerm) => ({ ...prevTerm, valid: value }))
                                            setVerifiedSelectOpen(false)
                                        }}
                                        sx={{
                                            borderRadius: 0,
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'transparent',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: '#0000008F !important',
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>-</MenuItem>
                                        <MenuItem value={1}>Да</MenuItem>
                                        <MenuItem value={2}>Не</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] min-w-[250px] !p-0">
                                    <TextField
                                        placeholder="Претрага по броју телефона"
                                        sx={{ ...textFieldStyles }}
                                        className="w-full"
                                        name="phone"
                                        value={searchTerm.phone}
                                        onChange={handleSearchChange}
                                    />
                                </TableCell>
                                <TableCell className="border border-[#E0E0E0] !p-0"></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {users?.map((u) => (
                                <TableRow key={u?.id}>
                                    <TableCell className="border border-[#E0E0E0]">{u?.title || (u?.firstName && u?.lastName) ? `${u.firstName} ${u.lastName}` : '-'}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.email}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.city}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.role ? 'Администратор' : 'Корисник'}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">
                                        <Tooltip title={u?.category || '-'} placement="top" arrow>
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '200px',
                                                }}
                                            >
                                                {u?.category}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]">
                                        <Tooltip title={u?.subCategory || '-'} placement="top" arrow>
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '240px',
                                                }}
                                            >
                                                {u?.subCategory}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]">
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#243964', color: 'white' }}
                                            onClick={() => {
                                                navigate(`/user/details?userId=${u?.id}`)
                                            }}
                                        >
                                            Детаљи
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.pib}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.mb}</TableCell>
                                    <TableCell className="flex flex-end border border-[#E0E0E0]">
                                        <Button variant="contained" style={{ backgroundColor: '#243964', color: 'white' }} onClick={() => handleSendResetPassword(u.email)}>
                                            Опоравак лозинке
                                        </Button>
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.verified ? 'Да' : 'Не'}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">{u?.phone}</TableCell>
                                    <TableCell className="border border-[#E0E0E0]">
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#243964', color: 'white' }}
                                            onClick={() => {
                                                setIsModalOpen(true)
                                                setSelectedUser(u.id)
                                            }}
                                        >
                                            Деактивирај
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Број редова по страни:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} од ${count}`}
                    component="div"
                    count={total || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </Paper>
            {showBackToTop && (
                <Button
                    onClick={scrollToTop}
                    variant="contained"
                    style={{
                        position: 'fixed',
                        bottom: '30px',
                        right: '30px',
                        backgroundColor: '#243964',
                        color: 'white',
                    }}
                >
                    Назад на врх
                </Button>
            )}

            <YesNoModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirm}
                label="Да ли сте сигурни да желите да обришете корисника?"
                subText="Потврдите ваш избор"
            />
        </div>
    )
}

export default UserManagementScreen
