import React from 'react'
import { StepInterfaceObl2 } from './Step1'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { getPercentage, sumValues } from '../../../utils/mathUtils'
import { useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../Components/PositiveNumberInputCell'
import HelpButton from '../../../components/Common/HelpButton'

export interface RowInterface {
    field: keyof TblOblast2
    label: string
}
const rows: RowInterface[] = [
    { field: 'o2_k5_p_01', label: 'Председник/ца тела за родну равноправност' },
    { field: 'o2_k5_p_02', label: '' },
    { field: 'o2_k5_p_03', label: '' },
    { field: 'o2_k5_z_01', label: 'Заменик/ца прeдседника/це тела зa родну равноправност' },
    { field: 'o2_k5_z_02', label: '' },
    { field: 'o2_k5_z_03', label: '' },
    { field: 'o2_k5_c_01', label: 'Чланови/чланице тела за родну равноправност' },
    { field: 'o2_k5_c_02', label: '' },
    { field: 'o2_k5_c_03', label: '' },
    { field: 'o2_k5_s_01', label: 'Секретар/ка тела за родну равноправност' },
    { field: 'o2_k5_s_02', label: '' },
    { field: 'o2_k5_s_03', label: '' },
]

const Step5 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fieldsToSum = rows.map((row) => row.field)

    const col1sum = sumValues(
        watchedValues,
        fieldsToSum.filter((field) => field.endsWith('_01'))
    )
    const col2sum = sumValues(
        watchedValues,
        fieldsToSum.filter((field) => field.endsWith('_02'))
    )
    const col3sum = sumValues(
        watchedValues,
        fieldsToSum.filter((field) => field.endsWith('_03'))
    )

    const getSum = (index: number) => {
        switch (index % 3) {
            case 0:
                return col1sum
            case 1:
                return col2sum
            case 2:
                return col3sum
            default:
                return 0
        }
    }

    const getPercentageForField = (field: keyof TblOblast2, index: number) => {
        const sum = getSum(index)
        return getPercentage(watchedValues[field], sum)
    }

    const disabledNext = !rows.every((row) => watchedValues[row.field] !== null && watchedValues[row.field] !== undefined && watchedValues[row.field] !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name">
                Уколико чланови/чланице или руководећи и извршни састав тела за родну равноправност по том основу остварује право на плату или другу накнаду, унети тражене податке
                исказане у просечном месечном номиналном износу, разврстане по полу, у назначеним категоријама и друге накнаде запослених и радно ангажованих лица исказане у
                просечном номиналном износу разврстане по полу у категоријама за извршилачка радна места и за положаје
            </h1>
            <div className="flex flex-row gap-2 ">
                <table className="mt-[20px] !w-[1188px] text-center border-separate border-spacing-y-[5px] border-spacing-x-[5px]">
                    <thead>
                        <tr className="w-[1188px] px-[5px]">
                            <th className="!min-w-[270px] bg-primary text-center text-[#f5f5f5] border border-[#4891cb] text-[14px]" rowSpan={2}>
                                Позиција у телу за родну равноправност
                            </th>
                            <th className="w-[300px] bg-[#A2CEFF] py-[15px] text-[#f5f5f5] border border-[#4891cb] text-[14px] px-[25px]" colSpan={2}>
                                ЖЕНЕ – Просечан месечни номинални износ и проценат плата и других доприноса у протеклих 12 месеци
                            </th>
                            <th className="w-[300px] bg-primary py-[15px] text-[#f5f5f5] border border-[#4891cb] text-[14px] px-[25px]" colSpan={2}>
                                МУШКАРЦИ - Просечан месечни номинални износ и проценат плата и других доприноса у протеклих 12 месеци
                            </th>
                            <th className="w-[300px] bg-primary py-[15px] text-[#f5f5f5] border border-[#4891cb] text-[14px] px-[25px]" colSpan={2}>
                                УКУПНО - Просечан месечни номинални износ и проценат плата и других доприноса у протеклих 12 месеци
                            </th>
                        </tr>
                        <tr>
                            <th className="bg-primary w-[150px] py-[15px] px-[54px] text-[#ffffff] border border-[#4891cb] text-[14px]">Износ</th>
                            <th className="bg-primary w-[150px] py-[15px] px-[41px] text-[#f5f5f5] border border-[#4891cb] text-[14px]">Проценат</th>
                            <th className="bg-primary w-[150px] py-[15px] px-[54px] text-[#f5f5f5] border border-[#4891cb] text-[14px]">Износ</th>
                            <th className="bg-primary w-[150px] py-[15px] px-[41px] text-[#f5f5f5] border border-[#4891cb] text-[14px]">Проценат</th>
                            <th className="bg-primary w-[150px] py-[15px] px-[54px] text-[#f5f5f5] border border-[#4891cb] text-[14px]">Износ</th>
                            <th className="bg-primary w-[150px] py-[15px] px-[41px] text-[#f5f5f5] border border-[#4891cb] text-[14px]">Проценат</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => {
                            if (index % 3 === 0) {
                                return (
                                    <tr key={row.field}>
                                        <th className=" text-disabled border border-disabled font-normal text-[14px]">{row.label}</th>
                                        {[0, 1, 2].map((i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <td className="w-[150px] text-disabled">
                                                        <PositiveNumberInputCell
                                                            name={rows[index + i].field as string}
                                                            control={control}
                                                            defaultValue=""
                                                            height={56}
                                                            isStep5={true}
                                                            errors={errors}
                                                            isRequired={!adminView}
                                                            disabled={adminView}
                                                        />
                                                    </td>
                                                    <td className="w-[150px] border border-disabled text-disabled bg-[#f5f5f5] px-[15px] py-[15px] text-right">
                                                        {getPercentageForField(rows[index + i].field, index + i)}%
                                                    </td>
                                                </React.Fragment>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                            return null
                        })}
                    </tbody>
                </table>
                <HelpButton name="o2-k5" marginTop={25} />
            </div>
            <div className="h-[20px]">{Object.keys(errors).length > 0 && <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">Попуните обавезно поље.</p>}</div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
