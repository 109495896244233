export enum GRADE {
    'Odlično',
    'Vrlo dobro',
    'Zadovoljava',
    'Nedovoljno',
}

export enum YESNOOTHER_ENUM {
    Yes = 0,
    No = 1,
    Other = 2,
}

export enum STATUS {
    Започет = 0,
    Завршен = 1,
    Закључан = 2,
}

export type Filter = {
    reportYears: number[]
    statuses: number | null
    categories: string[]
    subCategories: string[]
}

export enum FINANCIAL_SOURCES {
    'Буџетска' = 0,
    'Донаторска' = 1,
}
