import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as Edit } from '../../assets/svg/Edit.svg'
import { useNavigate } from 'react-router-dom'
import MDEditor from '@uiw/react-md-editor'
import { useUser } from '../../auth/UseUser'
import { SubmitHandler, useForm } from 'react-hook-form'
import { editHelpContent } from '../../server/server'

interface HelpRow {
    id: number
    name: string
    title: string
    text: string
    created: string
}

interface ModalInterface {
    modalIsOpen: boolean
    closeModal: () => void
    helpContent?: any
}

function HelpMessageModal({ modalIsOpen, closeModal, helpContent }: ModalInterface) {
    const [editMode, setEditMode] = useState(false)
    const [helpMessage, setHelpMessage] = useState<any>(helpContent)
    const { user } = useUser()
    const adminView = user.admin
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        setValue,
    } = useForm<HelpRow>()
    const navigate = useNavigate()
    const [text, setText] = useState<string | undefined>('')

    useEffect(() => {
        if (modalIsOpen) {
            ReactModal.setAppElement('#root')
        }
    }, [modalIsOpen])

    const changeMode = () => {
        setEditMode(true)
    }

    useEffect(() => {
        if (modalIsOpen && !!helpContent) {
            setValue('name', helpContent.name)
            setValue('title', helpContent.title)
            setText(helpContent.text)
            setHelpMessage(helpContent)
        }
    }, [modalIsOpen, helpContent])

    const onSubmit: SubmitHandler<HelpRow> = async () => {
        const data = {
            name: getValues('name'),
            title: getValues('title'),
            text: text || '',
        }
        const resp = await editHelpContent(data)
        if (resp.status === 201) {
            alert('Упутство је успешно ажурирано!')
            setHelpMessage(resp.data)
            setEditMode(false)
        } else {
            alert(`Грешка при чувању!, ${resp.status}`)
        }
    }

    const resetValues = () => {
        setEditMode(false)
        const el = document.getElementById('scroll-target')
        if (el) el.classList.remove('no-scroll-behind')
    }

    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={modalIsOpen}
            onAfterOpen={() => {
                const el = document.getElementById('scroll-target')
                if (el) el.classList.add('no-scroll-behind')
            }}
            onAfterClose={() => resetValues()}
            onRequestClose={closeModal}
            className="no-scrollbar outline-0"
            style={{
                overlay: {
                    zIndex: 5,
                    background: '#000000A6',
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: 800,
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    maxHeight: '100%',
                    border: '0px',
                    boxShadow: 'none',
                    paddingTop: 30,
                    paddingBottom: 15,
                    overflow: 'auto',
                },
            }}
        >
            <div className="w-full  bg-[white] px-[30px] py-[40px] rounded-[4px] ">
                {!editMode ? (
                    <div className="flex flex-col items-center gap-[20px] ">
                        <h2 className="font-bold text-[20px] text-center">{helpMessage?.title || 'Учитавање...'}</h2>

                        <MDEditor.Markdown source={helpMessage?.text || 'Учитавање..'} style={{ backgroundColor: '#ffffff' }} />

                        <div className="w-full flex flex-row justify-center gap-2 mt-[30px] relative">
                            <button
                                onClick={closeModal}
                                className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer max-w-[520px]"
                            >
                                Затвори
                            </button>
                            {adminView && (
                                <button
                                    onClick={changeMode}
                                    className="absolute bottom-0 right-0 border border-[#303030] h-[42px] px-2 flex flex-row items-center justify-center gap-1 rounded-[4px] bg-white cursor-pointer "
                                >
                                    <Edit />
                                    <p className="text-[15px] text-[#303030] font-medium leading-[26px]">Измени</p>
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center gap-[20px] ">
                        <h2 className="text-[24px] leading-[28px] font-light max-w-[800px]">Ажурирање упутства</h2>
                        <form className="flex flex-col items-center w-full max-w-[800px]" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="flex flex-col w-full mb-[16px]">
                                <label>Наслов упутства</label>
                                <input
                                    className="border border-[#0000003B] max-w-full min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                                    placeholder="Назив"
                                    {...register('title', {
                                        required: true,
                                    })}
                                />
                                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[3px]">{errors.title?.type === 'required' ? 'Назив is required' : ''}</span>
                            </div>
                            <div className="flex flex-col w-full mb-[16px]">
                                <label>Текст</label>
                                <div data-color-mode="light">
                                    <MDEditor value={text} onChange={setText} height={450} style={{ backgroundColor: '#ffffff' }} />
                                </div>
                            </div>
                            <div className="flex flex-row items-center justify-between gap-[20px] w-full mt-[30px]">
                                <button
                                    type="button"
                                    onClick={() => setEditMode(false)}
                                    className="w-full h-[42px] flex items-center justify-center rounded-[4px] border border-[#243964] text-[15px] text-[#243964] font-medium leading-[26px] cursor-pointer max-w-[520px]"
                                >
                                    Откажи
                                </button>
                                <button
                                    type="submit"
                                    className="w-full h-[42px] flex items-center justify-center rounded-[4px] bg-[#243964] text-[15px] text-[#ffffff] font-medium leading-[26px] cursor-pointer max-w-[520px]"
                                >
                                    Сачувај
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </ReactModal>
    )
}

export default HelpMessageModal
