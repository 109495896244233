import { Button, Modal, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseFilled } from '../../assets/svg/CloseFilled.svg'
import Textbox from '../Contact/TextBoxAutoSize'

interface modalInterface {
    isOpen: boolean
    onClose: any
    onConfirm: any
    category: any
}
export const EditCategoryNameModal = ({ isOpen, onClose, onConfirm, category }: modalInterface) => {
    const [newName, setNewName] = useState('')
    useEffect(() => {
        setNewName(category?.name)
    }, [category])
    if (!isOpen) return null

    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className="flex flex-col justify-center items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white min-w-[40%] max-w-[40%] h-full max-h-[40%] p-5 shadow-lg rounded-lg">
                <TextareaAutosize
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    className="border border-[#0000003B] max-w-full w-full min-h-[56px] px-3 rounded-[4px] outline-none text-[16px] leading-[24px]"
                />
                <div className="mt-[40px] flex flex-row justify-between w-[80%] gap-[10px]">
                    <Button
                        onClick={onClose}
                        style={{
                            width: '100%',
                            height: '42px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '4px',
                            border: '1px solid #243964',
                            color: '#243964',
                            fontSize: '15px',
                            fontWeight: '500',
                            lineHeight: '26px',
                        }}
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={() => {
                            onConfirm(newName)
                        }}
                        className="w-full h-[42px] flex items-center justify-center rounded-[4px] border !bg-[#243964] text-[15px] !text-white font-medium leading-[26px]"
                    >
                        Измени
                    </Button>
                </div>
                <Button
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '16px',
                        cursor: 'pointer',
                        color: 'black',
                    }}
                >
                    <CloseFilled />
                </Button>
            </div>
        </Modal>
    )
}
