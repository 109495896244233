import React from 'react'
import HelpButton from '../../components/Common/HelpButton'
import { Button } from '@mui/material'
import { ObrazacButton } from '../Home/HomeScreen'

function AnalyticsScreen() {
    return (
        <div className="!w-full px-[100px] bg-[#ffffff] pt-[52px]">
            <div className="flex flex-row items-center gap-4 w-full justify-between">
                <h2 className="text-[24px] font-light max-w-[800px]">Аналитика система</h2>

                <HelpButton name="home_screen_help" marginTop={0} />
            </div>

            <div className="flex flex-row gap-2 mt-10">
                <ObrazacButton text="Образац 1" path="/admin/analytics/form1" />
                <ObrazacButton text="Образац 2" path="/admin/analytics/form2" disabled />
                <ObrazacButton text="Образац 3" path="/admin/analytics/form3" disabled />
                <ObrazacButton text="Образац 4" path="/admin/analytics/form4" disabled />
                <ObrazacButton text="Образац 5" path="/admin/analytics/form5" disabled />
                <ObrazacButton text="Образац 6" path="/admin/analytics/form6" disabled />
            </div>
        </div>
    )
}

export default AnalyticsScreen
