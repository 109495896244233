import { TblOblast1 } from '../../../../models/Form1/Form1.entity'

import ActionButtons from '../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import SimpleTable from '../../../../components/Tables/SimpleTable'
import { StepInterface } from './Step1'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'
export interface RowInterface {
    label: string
    field: keyof TblOblast1
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o1_k16_m_tr_bpp' },
    { label: 'Жене', field: 'o1_k16_z_tr_bpp' },
]
function Step16({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, countType, setValue }: StepInterface) {
    const fields: ('o1_k16_m_tr_bpp' | 'o1_k16_z_tr_bpp')[] = ['o1_k16_m_tr_bpp', 'o1_k16_z_tr_bpp']

    useEffect(() => {
        loadData()
    }, [countType])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">16. Број тражилаца и корисника бесплатне правне помоћи разврстаних по полу:</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={true} />
                <HelpButton name="o1-k16" marginTop={30} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step16
