import '../../../assets/styles/ComplexTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import { StepInterface } from './Step1'
import { Button, TextareaAutosize } from '@mui/material'
import { useState } from 'react'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import HelpButton from '../../../components/Common/HelpButton'

function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })
    const [textbox, setTextbox] = useState('')
    const [error, setError] = useState<any>({
        active: false,
        text: '',
    })
    const disabledNext = watchedValues?.o6_k6_informacije?.length == 0 || watchedValues?.o6_k6_informacije?.length == undefined
    const disabledAddMore = textbox.trim() === ''

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o6_k6_informacije',
    })

    const handleAddField = () => {
        setTextbox('')
        append({ text_content: textbox })
        setError({ active: false, text: '' })
    }

    const goToNexStep = () => {
        if (adminView) {
            nextStep()
        } else if (disabledNext && disabledAddMore) {
            setError({
                active: true,
                text: 'Попуните обавезно поље.',
            })
        } else if (disabledNext && !disabledAddMore) {
            setError({
                active: true,
                text: 'Кликни на дугме Додај информацију',
            })
        } else {
            setError({
                active: false,
                text: '',
            })
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">
                Информације о спроведеним мерама предвиђеним планом управљања ризицима од повреде принципа родне равноправности
            </h1>

            <div className="mt-[30px]">
                <div className="flex flex-col gap-[20px] mb-[30px]">
                    {fields.map((field, index) => (
                        <div className="flex flex-row gap-[50px]">
                            <p className=" w-[1028px] break-words">{field.text_content}</p>
                            {!adminView && (
                                <div className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                    <Delete />
                                </div>
                            )}
                        </div>
                    ))}
                    {!fields?.length && <div className="text-inactive">Тренутно нема унетих информација</div>}
                </div>
                <div className=" flex flex-row gap-2">
                    <TextareaAutosize
                        value={textbox}
                        onChange={(e) => {
                            setTextbox(e.target.value)
                            setError({ active: false, text: '' })
                        }}
                        minRows={4}
                        placeholder={'Текст (2048 карактера)'}
                        className={`border ${!error.active ? 'border-[#D9D9D9] ' : 'border-[#d32f2f] placeholder-[#d32f2f85]'} `}
                        style={{
                            width: '100%',
                            padding: '12px 16px',
                            boxSizing: 'border-box',
                            borderRadius: '8px',
                            resize: 'none',
                            maxWidth: 1028,
                        }}
                        maxLength={2048}
                        disabled={adminView}
                    />
                    <HelpButton name="o6-k6" />
                </div>
                <div className="h-[26px]">{error.text == 'Попуните обавезно поље.' && <p className=" leading-5 ml-[20px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}</div>
            </div>
            <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore} style={{ marginTop: 30 }}>
                Додај информацију
            </Button>
            <div className="h-[26px]">{error.text == 'Кликни на дугме Додај информацију' && <p className="leading-6 ml-[10px] text-[#d32f2f] text-[0.75rem]">{error.text}</p>}</div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
