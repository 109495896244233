import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { ReactComponent as TickMark } from '../../assets/svg/ModalTickMark.svg'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface ModalInterface {
    modalIsOpen: boolean
    closeModal: () => void
    infoText: string
    adminView: any
}

const surveyUrl = '/anketa'
const dashboardUrl = '/admin/dashboard'

function FinishOfFormModal({ modalIsOpen, closeModal, infoText, adminView }: ModalInterface) {
    const navigate = useNavigate()

    useEffect(() => {
        if (modalIsOpen) {
            ReactModal.setAppElement('#root')
        }
    }, [modalIsOpen])

    const openSurvey = () => {
        navigate(surveyUrl)
    }

    const openFormSearch = () => {
        navigate(dashboardUrl)
    }

    return (
        <ReactModal
            ariaHideApp={false}
            //closeTimeoutMS={600}
            isOpen={modalIsOpen}
            onAfterOpen={() => {}}
            onAfterClose={() => {}}
            onRequestClose={closeModal}
            className="no-scroll border-0"
            style={{
                overlay: {
                    zIndex: 5,
                    background: '#000000A6',
                },
                content: {
                    padding: 0,
                    opacity: 1,
                    width: '100%',
                    margin: 'auto',
                    backgroundColor: 'transparent',
                    height: '100%',
                    maxHeight: '100%',
                    overflow: 'auto',
                    paddingTop: 15,
                    paddingBottom: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <div className="w-[650px] h-[510px] bg-[white] px-[65px] py-[60px] rounded-[4px] flex flex-col items-center gap-[20px]">
                {!adminView ? (
                    <>
                        <TickMark />
                        <h2 className="font-bold text-[20px]">Ваши одговори су успешно сачувани!</h2>
                        <p className="text-[16px]">Хвала вам што сте попунили {infoText}.</p>
                        <p className="text-[16px]">Ваше мишљење нам је важно! Молимо вас да попуните кратку анкету о вашем искуству са попуњавањем овог обрасца.</p>

                        <div className="flex flex-col gap-[20px] mt-[30px]">
                            <Button variant="contained" color="info" onClick={openSurvey}>
                                Попуните анкету
                            </Button>
                            <Button variant="contained" size="medium" onClick={closeModal}>
                                почетна страница
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <TickMark />
                        <h2 className="font-bold text-[20px] mb-[10px]">Крај извештаја</h2>
                        <p className="text-[16px] mb-[50px] text-center">Успешно сте прегледали извештај: {infoText}.</p>
                        <div className="flex flex-col gap-[20px] mt-[30px]">
                            <Button variant="contained" size="medium" onClick={openFormSearch}>
                                Претрага извештаја
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </ReactModal>
    )
}

export default FinishOfFormModal
