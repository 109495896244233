import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../../components/Common/Textbox'
import HelpButton from '../../../components/Common/HelpButton'

export interface StepInterface {
    control?: Control<TblOblast4>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    isCommission?: boolean
    clearErrors?: any
    adminView?: any
    setValue?: any
}

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, isCommission, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })

    const disabledNext = watchedValues?.o4_k1_ocena === undefined || watchedValues?.o4_k1_razlozi?.length === 0

    const radioOptions = ['Одлично', 'Врло добро', 'Задовољава', 'Недовољно']

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[10px] text-[20px]">
                {isCommission ? 'Овај образац попуњавате као: Комисија за родну равноправност' : 'Овај образац попуњавате као: Савет за родну равноправност'}
            </h1>
            <h1 className="step-name mb-[10px]">Оцена стања родне равноправности у години вођења евиденције о остваривању родне равноправности:</h1>
            <div className="mt-[20px] flex flex-row gap-2">
                <RadioButtonsControl control={control} name={'o4_k1_ocena'} options={radioOptions} errors={errors} enumerated disabled={adminView} />
                <HelpButton name="o4-k1" marginTop={10} />
            </div>
            <h1 className="step-name mb-[10px] mt-[20px] opacity-[60%]">Разлози због којих je дошло/није дошло до унапређења родне равноправности:</h1>
            <Textbox
                name="o4_k1_razlozi"
                control={control}
                maxNumberOfCharacters={2048}
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
