import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast5, YESNO } from '../../../models/Form5/Form5.entity'
import RadioButtonsControl from '../../../components/Common/RadioButtonsControl'
import HelpButton from '../../../components/Common/HelpButton'

export interface StepInterface {
    control?: Control<TblOblast5>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    isParty?: boolean
    errors?: any
    adminView?: boolean
    trigger?: any
    setValue?: any
}

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, isParty, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast5>({ control })
    const fields: ('o5_k1_r1' | 'o5_k1_r2' | 'o5_k1_r3')[] = ['o5_k1_r1', 'o5_k1_r2', 'o5_k1_r3']
    const disabledNext = !fields.every((e) => !!watchedValues[e])

    const goToNexStep = () => {
        const skip = watchedValues.o5_k1_r1 === YESNO.Ne && watchedValues.o5_k1_r2 === YESNO.Ne && watchedValues.o5_k1_r3 === YESNO.Ne
        if (adminView) nextStep()
        else if (!disabledNext && !skip) nextStep()
        else if (!disabledNext && skip) nextStep(skip)
    }

    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">
                {isParty ? 'Да ли су актима ваше политичке странке регулисана следећа права:' : 'Да ли су актима ваше синдикалне организације регулисана следећа права:'}
            </h1>
            <div className="flex flex-col gap-[30px] mt-5">
                <RadioButtonsControl
                    control={control}
                    name={fields[0]}
                    errors={errors}
                    options={[YESNO.Da, YESNO.Ne]}
                    label={
                        isParty
                            ? '1)	Право на учлањење у политичку странку које сe остварује без било ког вида дискриминације на основу пола, односно рода'
                            : '1)	Право на учлањење у синдикалну организацију које сe остварује без било ког вида дискриминације на основу пола, односно рода'
                    }
                    isDisabled={adminView}
                    showHelpMessage
                    helpName="o5-k1"
                />

                <RadioButtonsControl
                    control={control}
                    name={fields[1]}
                    options={[YESNO.Da, YESNO.Ne]}
                    errors={errors}
                    label={
                        isParty
                            ? '2)	Право на активно учешће у раду политичке странке које се остварује без било ког вида дискриминације на основу пола, односно рода'
                            : '2)	Право на активно учешће у раду синдикалне организације које се остварује без било ког вида дискриминације на основу пола, односно рода'
                    }
                    isDisabled={adminView}
                />
                <RadioButtonsControl
                    control={control}
                    name={fields[2]}
                    options={[YESNO.Da, YESNO.Ne]}
                    errors={errors}
                    label="3)	Право на активно учешће у раду органа, укључујући и погодности које обезбеђују, којe се остварују без било ког вида дискриминације на основу пола, односно рода"
                    isDisabled={adminView}
                />
            </div>
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
