export const FORM1_NAMES = [
    '01. Број запослених и радно ангажованих лица',
    '02. Старосна структура',
    '03. Квалификације које поседују запослена и радно ангажована лица',
    '04. Број запослених и радно ангажованих лица на извршилачким радним местима и на положајима разврстаних по полу',
    '05. Плате и друге накнаде запослених и радно ангажованих лица исказане у  просечном номиналном износу разврстане по полу у категоријама за извршилачка  радна места и за положаје',
    '06. Број запослених и радно ангажованих лица која су у години вођења евиденције запошљавана односно радно ангажована, разврстаних по полу и старосној доби',
    '07. Број запослених и радно ангажованих лица која су у години вођења евиденције добила отказ, односно која су отпуштена, разврстаних по полу и старосној доби',
    '08. Број кандидата пријављених на конкурсе за попуњавање положаја или извршилачких радних места у органима јавне власти, односно за заснивање радног односа или ради другог вида радног ангажовања код послодавца разврстаних по полу и по квалификацијама у години вођења евиденције',
    '09. Број примљених пријава запослених и радно ангажованих лица о њиховој изложености узнемиравању, сексуалном узнемиравању или уцењивању или неком другом поступању које има за последицу дискриминацију на основу пола, односно рода, разврстаних по полу и старосној доби',
    '10. Начин поступања',
    '11. Број судских спорова који су вођени у вези са дискриминацијом на основу пола, односно рода, у години вођења евиденције ',
    '12. Број судских спорова који су окончани у корист запосленог, у години вођења евиденције',
    '13. Начина поступања одговорног лица у органима јавне власти и послодавца по тим судским одлукама',
    '14. Број запослених и радно ангажованих лица укључених у програме стручног усавршавања и додатног образовања, као и стручног оспособљавања приправника, разврстаних по полу, у години вођења евиденције ',
    '15. Број лица на извршилачким радним местима и на положајима, ангажованих у органима управљања и надзора, комисијама и другим телима код органа јавне власти и послодавца, разврстаних по полу, квалификацијама и старосној доби ',
    '16. Број  тражилаца и корисника бесплатне правне помоћи разврстаних по полу ',
    '17. Број пријављених случајева насиља у органу јавне власти или код послодавца по основу пола, односно рода, разврстаних по облику насиља, полу жртве насиља и полу извршиоца насиља, као и међусобном односу жртве и извршиоца насиља, у години вођења евиденције ',
    '18. Подаци о лицу задуженом за родну равноправност  код органа јавне власти који имају више од 50 заполених и радно ангажованих лица (уколико постоје у бази за ово правно лице повући ће се аутоматски а коринсик може да едитује)',
    'Заврши',
]
