import { Control, FieldValues, useWatch } from 'react-hook-form'
import { getPercentage, sumValues } from '../../utils/mathUtils'
import PositiveNumberInputCell from '../../screens/Form2/Components/PositiveNumberInputCell'

interface RowInterface<T> {
    label: string
    fields: (keyof T)[]
}

interface ComplexTableInterface<T extends FieldValues> {
    rows: RowInterface<T>[]
    control: Control<T> | undefined
    allFields: (keyof T)[]
    allMaleFields: (keyof T)[]
    allFemaleFields: (keyof T)[]
    header: string[]
    decimal?: boolean
    errors?: any
    disabled?: boolean
}
function ComplexTable<T extends FieldValues>({ rows, control, header, allFields, allMaleFields, allFemaleFields, decimal = false, errors, disabled }: ComplexTableInterface<T>) {
    type AllFieldsType = (typeof allFields)[number]
    type MaleFieldsType = (typeof allMaleFields)[number]
    type FemaleFieldsType = (typeof allFemaleFields)[number]

    const watchedValues = useWatch({ control }) as T
    const fields: AllFieldsType[] = allFields
    const maleFields: MaleFieldsType[] = [...allMaleFields]
    const femaleFields: FemaleFieldsType[] = [...allFemaleFields]
    const totalSum = sumValues(watchedValues, fields)
    const sumMale = sumValues(watchedValues, maleFields)
    const sumFemale = sumValues(watchedValues, femaleFields)

    const hasRowErrors = rows.some((row) => row.fields.some((field) => errors?.[field]))

    return (
        <table className="complex-table mt-[30px]">
            <tr className="complex-table-header">
                {header.map((head, index) => (
                    <th className="complex-table-cell" key={index}>
                        {head}
                    </th>
                ))}
            </tr>
            {rows.map((row) => (
                <tr className="complex-table-row">
                    <td className="complex-table-cell disabled">{row.label}</td>
                    <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                        {/* <PositiveNumberInput name={row.fields[0] as string} control={control} defaultValue="" decimal={decimal} /> */}
                        <PositiveNumberInputCell
                            name={row.fields[0] as string}
                            control={control}
                            decimal={decimal}
                            isMultipleTable={true}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className="complex-table-cell disabled">{getPercentage(watchedValues[row.fields[0]], sumFemale)}%</td>
                    <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                        {/* <PositiveNumberInput name={row.fields[1] as string} control={control} defaultValue="" decimal={decimal} /> */}
                        <PositiveNumberInputCell
                            name={row.fields[1] as string}
                            control={control}
                            decimal={decimal}
                            isMultipleTable={true}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className="complex-table-cell disabled">{getPercentage(watchedValues[row.fields[1]], sumMale)}%</td>
                    <td className="complex-table-cell disabled">{sumValues(watchedValues, row.fields)}</td>
                    <td className="complex-table-cell disabled">{getPercentage(sumValues(watchedValues, row.fields), totalSum)}%</td>
                </tr>
            ))}
            <tr className="complex-table-footer">
                <td className="complex-table-cell">Укупно</td>
                <td className="complex-table-cell">{decimal ? sumFemale.toFixed(2) : sumFemale}</td>
                <td className="complex-table-cell">{getPercentage(sumFemale, sumFemale)}%</td>
                <td className="complex-table-cell">{decimal ? sumMale.toFixed(2) : sumMale}</td>
                <td className="complex-table-cell">{getPercentage(sumMale, sumMale)}%</td>
                <td className="complex-table-cell">{decimal ? totalSum.toFixed(2) : totalSum}</td>
                <td className="complex-table-cell">{getPercentage(totalSum, totalSum)}%</td>
            </tr>
            <div className="h-[2px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
        </table>
    )
}

export default ComplexTable
