import { StepInterfaceObl2 } from './Step1'
import Textbox from '../../../components/Common/Textbox'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import PositiveNumberInputCell from '../Components/PositiveNumberInputCell'
import HelpButton from '../../../components/Common/HelpButton'

const Step9 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })

    const fields: ('o2_k9_broj' | 'o2_k9_opis')[] = ['o2_k9_broj', 'o2_k9_opis']

    const tbDisabled = watchedValues.o2_k9_broj === 0
    const disabledNext = !fields.every((e) => {
        if (tbDisabled && e === 'o2_k9_opis') return true
        return watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== ''
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    const checkDisabled = () => {
        return tbDisabled || adminView
    }
    return (
        <div>
            <h1 className="step-name">Број иницираних и предложених мера за унапређење родне равноправности од стране тела за родну равноправност у години вођења евиденције:</h1>
            <div className="flex flex-row gap-2 ">
                <div className="mt-[20px] w-[140px] py-[15px] flex justify-end">
                    <PositiveNumberInputCell name={'o2_k9_broj'} control={control} decimal={false} errors={errors} isRequired={true} height={52} disabled={adminView} />
                </div>
                <HelpButton name="o2-k9" marginTop={35} />
            </div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">
                    Сажет опис иницираних и предложених мера за унапређење родне равноправности од стране тела за родну равноправност у години вођења евиденције:*
                </h4>
                <Textbox
                    name="o2_k9_opis"
                    control={control}
                    placeholder="Текст (2048 карактера)*"
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={!checkDisabled()}
                    disabled={checkDisabled()}
                    rules={checkDisabled() ? {} : { required: 'Обавезно попуните ово поље' }}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
export default Step9
