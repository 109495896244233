import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useFieldArray, useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { StepInterface } from './Step1'
import { useState } from 'react'
import { Button } from '@mui/material'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import TextInputCell from '../../../components/Common/TextInputCell'
import HelpButton from '../../../components/Common/HelpButton'

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o4_k4_specialMeasures',
    })

    const arrayName = 'o4_k4_specialMeasures'

    let disabledNext = watchedValues[arrayName]?.length === 0

    fields.forEach((item, index) => {
        const { name, from, to } = watchedValues[arrayName]?.[index] || {}

        if (!name || !from || !to) {
            disabledNext = true
            return
        }
    })

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ name: '', from: null, to: null })
    }

    const validateEmptyArray = () => {
        if (watchedValues[arrayName]?.length === 0 || watchedValues[arrayName] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    const header = ['Редни број', 'Назив мере', 'Период реализације од', 'Период реализације до']

    return (
        <div>
            <h1 className="step-name mb-[20px]">Период реализације сваке од посебних мера је (унети податке за сваку од назначених посебних мера)</h1>
            <table className="multiple-input-table mt-[20px] !w-full">
                <tr className="multiple-input-table-header">
                    {header.map((head, index) => (
                        <th className={`multiple-input-table-cell ${index === 1 ? 'min-w-[600px]' : ''}`} key={index}>
                            {head}
                        </th>
                    ))}
                    <HelpButton name="o4-k4" />
                </tr>
                {fields.map((field, index) => {
                    return (
                        <div className="flex flex-row">
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className="multiple-input-table-cell disabled" style={{ border: 'none' }}>
                                    {index + 1}
                                </td>
                                <td className="multiple-input-table-cell !border-none min-w-[600px] " style={{ border: 'none' }}>
                                    {/* <Textbox name={`${name}.${index}.activityName`} control={control} placeholder="(Текст,50 карактера)*" /> */}
                                    <TextInputCell
                                        name={`${arrayName}.${index}.name`}
                                        control={control}
                                        placeholder="Текст (50 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={50}
                                        height={80}
                                        isMultipleTable={true}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable" style={{ border: 'none' }}>
                                    <HiddenDatePicker
                                        tabIndex={0}
                                        name={`${arrayName}.${index}.from`}
                                        value={watchedValues[arrayName]?.[index]?.from}
                                        control={control}
                                        errors={errors}
                                        isRequired={!adminView}
                                        isArray={true}
                                        isFirst={true}
                                        maxDate={watchedValues && watchedValues[arrayName]?.[index]?.to}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className="multiple-input-table-cell editable" style={{ border: 'none' }}>
                                    <HiddenDatePicker
                                        tabIndex={0}
                                        name={`${arrayName}.${index}.to`}
                                        control={control}
                                        value={watchedValues[arrayName]?.[index]?.to}
                                        errors={errors}
                                        isRequired={!adminView}
                                        isSecond
                                        minDate={watchedValues && watchedValues[arrayName]?.[index]?.from}
                                        isArray={true}
                                        disabled={adminView}
                                    />
                                </td>
                            </tr>
                            {fields.length > 1 ? (
                                <div className="ml-[50px] flex justify-center items-center">
                                    <Button color="error" variant="contained" onClick={() => remove(index)} disabled={adminView}>
                                        Обриши
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}
            </table>
            <div className="h-[20px]">{Object.keys(errors)?.length > 0 && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <div className="flex flex-col  mt-[56px]">
                <Button variant="contained" className="max-w-[172px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај меру
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну меру!' : ''}</span>
            </div>
            {/* <Textbox
                name="o4_k4_stepen"
                control={control}
                maxNumberOfCharacters={100}
                errors={errors}
                isRequired={adminView ? false : 'Попуните обавезно поље.'}
                disabled={adminView}
            /> */}
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
