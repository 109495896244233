export const FORM6_NAMES = [
    '1. Извештај о реализацији Плана за управљања ризицима од повреде принципа родне равноправности ',
    '2. Резултати процене ризика који угрожавају примену принципа родне равноправности',
    '3. Молим Вас унесите назив ризика, вероватноћу и утицај',
    '4. Навести процену последица које су наступиле услед повреде принципа родне равноправности код запослених лица и/или странака у процесу рада',
    '5. Мере предвиђене планом управљања ризицима од повреде принципа родне равноправности',
    '6. Информације о спроведеним мерама предвиђеним планом управљања ризицима од повреде принципа родне равноправности',
    '7. Разлози због којих мере нису спроведене',
    '8. Лице задужено за сачињавање извештаја',
    '9. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
