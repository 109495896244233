import { StepInterfaceObl2 } from './Step1'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../components/Tables/ComplexTable'
import ActionButtons from '../../../components/ActionButtons'
import Textbox from '../../../components/Common/Textbox'
import HelpButton from '../../../components/Common/HelpButton'

interface RowInterface {
    label: string
    fields: (keyof TblOblast2)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-30 година живота', fields: ['o2_k7_z_15_30', 'o2_k7_m_15_30'] },
    { label: 'Од 31-40 година живота', fields: ['o2_k7_z_31_40', 'o2_k7_m_31_40'] },
    { label: 'Од 41-50 година живота', fields: ['o2_k7_z_41_50', 'o2_k7_m_41_50'] },
    { label: 'Од 51-60 година живота', fields: ['o2_k7_z_51_60', 'o2_k7_m_51_60'] },
    { label: 'Од 61-70 година живота и више', fields: ['o2_k7_z_61_70', 'o2_k7_m_61_70'] },
]

const allFields = [
    'o2_k7_m_15_30',
    'o2_k7_m_31_40',
    'o2_k7_m_41_50',
    'o2_k7_m_51_60',
    'o2_k7_m_61_70',
    'o2_k7_z_15_30',
    'o2_k7_z_31_40',
    'o2_k7_z_41_50',
    'o2_k7_z_51_60',
    'o2_k7_z_61_70',
    'o2_k7_razlog',
] as const

const allMaleFields = ['o2_k7_m_15_30', 'o2_k7_m_31_40', 'o2_k7_m_41_50', 'o2_k7_m_51_60', 'o2_k7_m_61_70'] as const
const allFemaleFields = ['o2_k7_z_15_30', 'o2_k7_z_31_40', 'o2_k7_z_41_50', 'o2_k7_z_51_60', 'o2_k7_z_61_70'] as const

type AllFieldsType = (typeof allFields)[number]

const Step7 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const filteredFields = allFields.filter((key) => key !== 'o2_k7_razlog')
    const tbDisabled = filteredFields.every((key) => watchedValues[key] === 0)

    const disabledNext = !fields
        .filter((e) => e === 'o2_k7_razlog' && !tbDisabled)
        .every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    const checkDisabled = () => {
        return tbDisabled || adminView
    }

    return (
        <div>
            <h1 className="step-name">
                Број и проценат лица која су у години вођења евиденције мање ангажована у реализацији активности у организацији тела за родну равноправност, разврстаних по полу и
                старосној доби
            </h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['Старост', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={adminView}
                />
                <HelpButton name="o2-k7" marginTop={30} />
            </div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px] text-disabled">Разлози за мању ангажованост:*</h4>
                <Textbox
                    name="o2_k7_razlog"
                    control={control}
                    placeholder="Текст (200 карактера)*"
                    errors={errors}
                    isRequired={checkDisabled()}
                    disabled={checkDisabled()}
                    rules={checkDisabled() ? {} : { required: 'Обавезно попуните ово поље' }}
                    maxNumberOfCharacters={200}
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step7
