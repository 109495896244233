import { TblOblast3 } from '../../../models/Form3/Form3.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast3>({ control })

    const fields: 'o3_k4_razlozi'[] = ['o3_k4_razlozi']
    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">Разлози за одређивање посебних мера и циљеви који се њима постижу:</h1>
            <div className=" flex flex-row gap-2">
                <Textbox
                    name="o3_k4_razlozi"
                    control={control}
                    placeholder="Текст (2048 карактера)*"
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
                <HelpButton name="o3-k4" />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
