import { Control, UseFormSetValue, useWatch } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import SimpleTable from '../../../components/Tables/SimpleTable'
import ActionButtons from '../../../components/ActionButtons'
import HelpButton from '../../../components/Common/HelpButton'

export interface StepInterfaceObl2 {
    control?: Control<TblOblast2>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    setValue?: UseFormSetValue<TblOblast2>
    errors?: any
    fields?: any
    remove?: any
    disabled?: any
    clearErrors?: any
    adminView?: boolean
}

export interface RowInterface {
    label: string
    field: keyof TblOblast2
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o2_k1_m' },
    { label: 'Жене', field: 'o2_k1_z' },
]

const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const fields: ('o2_k1_m' | 'o2_k1_z')[] = ['o2_k1_m', 'o2_k1_z']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">Број и проценат лица именованих у састав тела за родну равноправност</h1>
            <div className="flex flex-row gap-2 ">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
                <HelpButton name="o2-k1" marginTop={30} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
