import { useState } from 'react'
import { StepInterfaceObl2 } from './Step1'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch, useFieldArray } from 'react-hook-form'
import { TblOblast2 } from '../../../models/Form2/Form2.entity'
import TextTable from '../Components/TextTable'
import { Button } from '@mui/material'

const Step19 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterfaceObl2) => {
    const watchedValues = useWatch<TblOblast2>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o2_k19_aktivnosti',
    })
    const firstArrayName = 'o2_k19_aktivnosti'

    const addArrayField = () => {
        setEmptyArrayError(false)
        append({ text_content: '' })
    }

    const validateArray = (): boolean => {
        if (!watchedValues.o2_k19_aktivnosti || watchedValues.o2_k19_aktivnosti.length === 0) {
            return true
        }

        const newMap = watchedValues.o2_k19_aktivnosti?.map((field) => field.text_content !== '')
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateArray()

    const validateEmptyArray = () => {
        if (watchedValues[firstArrayName]?.length === 0 || watchedValues[firstArrayName] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="mt-[50px]">
                <h4 className="mb-[10px]">
                    Планиране активности сарадње тела за родну равноправност са другим радним телима или органима на републичком, покрајинском и локалном нивоу по питању
                    остваривања и унапређивања родне равноправности:
                </h4>
                <div className="mt-[50px]">
                    <div>
                        <TextTable
                            control={control}
                            name={firstArrayName}
                            errors={errors}
                            fields={fields}
                            remove={remove}
                            label={'Планиране активности'}
                            maxChar={2048}
                            disabled={adminView}
                            showHelpMessage
                            helpName="o2-k19"
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors && errors?.[firstArrayName] ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                    <div className="flex flex-col">
                        <Button variant="contained" className="max-w-[172px]" onClick={addArrayField} style={{ marginTop: 20 }} disabled={adminView}>
                            Додај активност
                        </Button>
                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
                    </div>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step19
