const DropdownItem = ({ onClick, children }: any) => (
    <li className="px-4 py-2 cursor-pointer hover:bg-[rgba(36,57,100,0.2)] text-[#243964] w-full text-center" onClick={onClick}>
        {children}
    </li>
)

export const DropdownMenu = ({ showDropdown, setShowDropdown, user, navigate, logout }: any) => {
    const handleItemClick = (path: any) => {
        navigate(path)
        setShowDropdown(false)
    }

    return (
        showDropdown && (
            <div className="absolute right-0 w-48 bg-white shadow-lg rounded-lg z-10 flex items-center" onMouseLeave={() => setShowDropdown(false)}>
                <ul className="w-full">
                    {user.firstName && user.category && (
                        <>
                            <DropdownItem onClick={() => handleItemClick('/user/profile')}>Измена профила</DropdownItem>
                            <DropdownItem onClick={() => handleItemClick('/user/password')}>Измена лозинке</DropdownItem>
                            <DropdownItem onClick={() => handleItemClick('/user/email-change')}>Измена имејла</DropdownItem>
                        </>
                    )}
                    <DropdownItem
                        onClick={() => {
                            logout()
                            setShowDropdown(false)
                        }}
                    >
                        Одјава
                    </DropdownItem>
                </ul>
            </div>
        )
    )
}
