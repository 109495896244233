import React, { useState, useEffect, useRef } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, TablePagination, Button, Box } from '@mui/material'
import { ReactComponent as Delete } from '../../assets/svg/Delete.svg'
import { ReactComponent as Edit } from '../../assets/svg/Edit.svg'
import { deleteCity, getAllCities } from '../../server/server'
import AddCityModal from './AddCityModal'
import HelpButton from '../../components/Common/HelpButton'
import { convertToCyrillic, convertToLatin, latinToCyrillic } from '../../utils/searchTranslation'

const CityScreen = () => {
    const [cities, setCities] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredCities, setFilteredCities] = useState(cities)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [selectedCity, setSelectedCity] = useState<any>(null)
    const scrollRef = useRef<any>(null)

    useEffect(() => {
        loadCities()
    }, [])

    const loadCities = async () => {
        const resp = await getAllCities()
        if (resp.status === 200) {
            setCities(resp.data)
        }
    }

    useEffect(() => {
        setFilteredCities(
            cities.filter(
                (city) =>
                    city.name.toLowerCase().includes(latinToCyrillic(searchTerm.toLowerCase())) ||
                    city.name.toLowerCase().includes(convertToLatin(searchTerm.toLowerCase())) ||
                    city.municipality.toLowerCase().includes(latinToCyrillic(searchTerm.toLowerCase())) ||
                    city.municipality.toLowerCase().includes(convertToLatin(searchTerm.toLowerCase()))
            )
        )
        setPage(0)
    }, [searchTerm, cities])

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage)
    }

    useEffect(() => {
        scrollToTop()
    }, [page])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleNewCity = () => {
        setSelectedCity(null)
        setOpenCreateModal(true)
    }

    const closeCreateModal = () => {
        setOpenCreateModal(false)
        setSelectedCity(null)
    }

    const reloadCities = () => {
        loadCities()
    }

    const onDelete = async (id: string) => {
        if (window.confirm('Да ли желите да обришете ово место?')) {
            const resp = await deleteCity(id)
            if (resp.status === 200) {
                loadCities()
                window.alert('Успешно обрисано место.')
            }
        } else {
        }
    }
    const onEdit = (city: any) => {
        setSelectedCity(city)
        setOpenCreateModal(true)
    }
    return (
        <div className="!w-full px-[100px] bg-[#ffffff] py-[52px]">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, mb: 2 }}>
                <h2 className="text-[24px] font-light max-w-[800px]">Управљање местима</h2>
                <HelpButton name="city-management" />
            </Box>
            <div className="flex flex-row  justify-between mb-2">
                <TextField label="Претрага места" variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: 350 }} />
                <Button variant="contained" onClick={() => handleNewCity()} style={{ backgroundColor: '#243964', color: 'white' }}>
                    Додај ново место
                </Button>
            </div>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="border border-[#E0E0E0]">Име места</TableCell>
                                <TableCell className="border border-[#E0E0E0]">Поштански број</TableCell>
                                <TableCell className="border border-[#E0E0E0]">Регион</TableCell>
                                <TableCell className="border border-[#E0E0E0]">Општина</TableCell>
                                <TableCell className="border border-[#E0E0E0]">Измене</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {filteredCities.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((city) => (
                                <TableRow key={city.id}>
                                    <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                        {city.name}
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                        {city.zip}
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                        {city.region}
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                        {city.municipality}
                                    </TableCell>
                                    <TableCell className="border border-[#E0E0E0]" sx={{ paddingY: '10px' }}>
                                        <IconButton color="primary" onClick={() => onEdit(city)}>
                                            <Edit />
                                        </IconButton>
                                        <IconButton color="secondary" onClick={() => onDelete(city.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={filteredCities.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[100, 200, 300]}
                    labelRowsPerPage="Број редова по страници"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} од ${count}`}
                />
            </Paper>
            <AddCityModal modalIsOpen={openCreateModal} closeModal={closeCreateModal} city={selectedCity} reloadCities={() => reloadCities()} />
        </div>
    )
}

export default CityScreen
