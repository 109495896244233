import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })

    const fields: 'o4_k3_spisak'[] = ['o4_k3_spisak']
    const disabledNext = !fields?.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">
                Списак иницираних посебних мера и њихови периоди извођења на нивоу јединице територијалне аутономије или јединице локалне самоуправе ради остваривања и унапређивања
                родне равноправности:
            </h1>
            <div className=" flex flex-row gap-2">
                <Textbox
                    name="o4_k3_spisak"
                    control={control}
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
                <HelpButton name="o4-k3" />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
