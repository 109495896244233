import { TblOblast1 } from '../../../../models/Form1/Form1.entity'
import ActionButtons from '../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../components/Tables/ComplexTable'
import { StepInterface } from './Step1'
import HelpButton from '../../../../components/Common/HelpButton'
import { useEffect } from 'react'
import { getAnalyticsDataForForm1 } from '../../../../server/server'

interface RowInterface {
    label: string
    fields: (keyof TblOblast1)[]
}

const rows: RowInterface[] = [
    { label: 'Лица на извршилачким радним местима', fields: ['o1_k4_zi', 'o1_k4_mi'] },
    { label: 'Лица на положајима/Руководилац', fields: ['o1_k4_zr', 'o1_k4_mr'] },
]

const allFields = ['o1_k4_zi', 'o1_k4_mi', 'o1_k4_zr', 'o1_k4_mr'] as const

const allMaleFields = ['o1_k4_mi', 'o1_k4_mr'] as const
const allFemaleFields = ['o1_k4_zi', 'o1_k4_zr'] as const

type AllFieldsType = (typeof allFields)[number]

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, countType, setValue }: StepInterface) {
    const fields: AllFieldsType[] = [...allFields]

    useEffect(() => {
        loadData()
    }, [countType])

    const loadData = async () => {
        const data = {
            type: countType,
            fields: fields,
        }
        const resp = await getAnalyticsDataForForm1(data)
        if (resp.status === 200) {
            const data = resp.data
            fields.forEach((field) => {
                setValue(field, parseFloat(data[field]))
            })
        }
    }

    return (
        <div>
            <h1 className="step-name">4. Број запослених и радно ангажованих лица на извршилачким радним местима и на положајима разврстаних по полу:</h1>
            <div className="flex flex-row gap-2 ">
                <ComplexTable
                    rows={rows}
                    control={control}
                    allFemaleFields={[...allFemaleFields]}
                    allMaleFields={[...allMaleFields]}
                    allFields={[...allFields]}
                    header={['', 'Жене', 'Жене%', 'Мушкарци', 'Мушкарци%', 'Укупно', 'Укупно%']}
                    errors={errors}
                    disabled={true}
                />
                <HelpButton name="o1-k4" marginTop={30} />
            </div>
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
