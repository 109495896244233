import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../components/ActionButtons'
import { TblOblast6 } from '../../../models/Form6/Form6.entity'
import HiddenDatePicker from '../../../components/Common/DatePicker'
import dayjs from 'dayjs'
import UploadFile from '../../../components/UploadFile'
import TextInputCell from '../../../components/Common/TextInputCell'
import SimpleInputRowForObject from '../../Form2/Components/SimpleImputRowForObject'
import HelpButton from '../../../components/Common/HelpButton'

export interface StepInterface {
    control?: Control<TblOblast6>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}

function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const fields: ('o6_k1_broj_plana' | 'o6_k1_datum' | 'o6_k1_mesto')[] = ['o6_k1_broj_plana', 'o6_k1_datum', 'o6_k1_mesto']

    const disabledNext = !fields.every((e) => (e in watchedValues ? !!watchedValues[e] : false))

    const showErrorMessage = errors?.o6_k1_broj_plana || errors?.o6_k1_datum || errors?.o6_k1_mesto

    const goToNexStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="text-[18px] leading-6 text-textPrimary ">Извештај о реализацији Плана за управљања ризицима од повреде принципа родне равноправности </h1>
            <div className="mt-[20px] flex flex-col gap-[15px] ml-[5px] max-w-[800px]">
                <div className="flex flex-row gap-2 ">
                    <div className="flex flex-row items-center gap-[10px]">
                        <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                            <p className="text-center mx-auto">Број плана</p>
                        </div>
                        {/* <PositiveNumberInputCell name={'o6_k1_broj_plana'} control={control} defaultValue="" errors={errors} isRequired height={50} /> */}
                        <TextInputCell
                            name={`o6_k1_broj_plana`}
                            control={control}
                            placeholder={`Tекст (30 карактера)`}
                            errors={errors}
                            isRequired={!adminView}
                            maxNumberOfCharacter={30}
                            isTextTable={true}
                            height={52}
                            disabled={adminView}
                        />
                    </div>
                    <HelpButton name="o6-k1" />
                </div>
                <div className="flex flex-row gap-[10px] h-[50px] max-w-[426px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Датум</p>
                    </div>
                    <HiddenDatePicker
                        tabIndex={0}
                        name={`o6_k1_datum`}
                        value={watchedValues?.o6_k1_datum}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        maxDate={dayjs(new Date())}
                        isFirst={true}
                        disabled={adminView}
                    />
                </div>
                <div className="flex flex-row items-center gap-[10px]">
                    <div className="w-[186px] border flex items-center justify-center h-[50px] bg-[#F5F5F5] text-disabled border-[#00000010]">
                        <p className="text-center mx-auto">Место доношења</p>
                    </div>
                    <TextInputCell
                        name={`o6_k1_mesto`}
                        control={control}
                        placeholder={`Tекст (50 карактера)`}
                        errors={errors}
                        isRequired={!adminView}
                        maxNumberOfCharacter={50}
                        isTextTable={true}
                        height={52}
                        disabled={adminView}
                    />
                </div>
            </div>
            <p className=" ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{showErrorMessage ? 'Попуните обавезно поље.' : ''}</p>
            <h1 className="text-[18px] leading-6 text-textPrimary mt-[20px]">Молим Вас приложите план у ПДФ формату до 2МБ</h1>
            <UploadFile control={control} name={'o6_k1_plan_file'} disabled={adminView} />
            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step1
