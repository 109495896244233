import axios, { AxiosRequestConfig } from 'axios'
import { LoginDto, SingUp } from '../models/User/userModel'
import { File as FileInterface } from '../models/File.entity'
import { baseUrl } from './connections'
import { MessageInputs } from '../screens/Contact/ContactScreen'

export type APREntity = {
    pib: string
    maticniBroj: string
    naziv: string
    adresa: string
    ovlascenoLice: string
    pravnaForma: string
}

export type APREntityAutoComplete = {
    pib: string
    maticniBroj: string
    naziv: string
    adresa: string
    ovlascenoLice: string
    pravnaForma: string
    ulica: string
    broj: string
    mesto: string
    ovlascenoLiceIme: string
    ovlascenoLicePrezime: string
}

export type FilterAPR = keyof APREntity

export type APREntityArrayFields = {
    [K in keyof APREntity]: string[]
}

export async function saveForm1(data: any, currentStep: number) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form1?currentStep=${currentStep}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm2(data: any, currentStep: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form2?currentStep=${currentStep}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm3(data: any, currentStep: number) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form3?currentStep=${currentStep}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm4(data: any, currentStep: number, category: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form4?currentStep=${currentStep}&category=${category}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm5(data: any, currentStep: any, org: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form5?currentStep=${currentStep}&orgType=${org}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm6(data: any, currentStep: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form6?currentStep=${currentStep}`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveForm7(data: any) {
    const options = {
        method: 'POST',
        url: `${baseUrl}/form7`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function saveAnketa(data: any) {
    const options = {
        method: 'POST',
        url: `${baseUrl}/anketa`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function uploadFile(file: File) {
    const formData = new FormData()
    formData.append('file', file)

    try {
        const response = await axios.post(`${baseUrl}/admin/common/file/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        if (response.status === 201) {
            return response.data.result as FileInterface
        }
    } catch (error) {
        console.error('Error uploading file:', error)
    }
}

export async function login(user: LoginDto) {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${baseUrl}/user/signin`,
        data: user,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function singUp(user: SingUp) {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${baseUrl}/user/signup`,
        data: user,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function whoAmI() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/whoami`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function sendRegisterToken(email: any) {
    // const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        url: `${baseUrl}/user/token/send`,
        data: email,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function verifyEmail(data: any) {
    // const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        // headers: {
        //     Authorization: `Bearer ${token}`,
        // },
        url: `${baseUrl}/user/verify/email`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function registerStep2(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/signup/continue`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getUnfinishedForm1() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form1`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUnfinishedForm2() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form2`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUnfinishedForm3() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form3`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUnfinishedForm4(category: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form4?category=${category}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUnfinishedForm5(category: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form5?orgType=${category}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUnfinishedForm6() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/form6`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForms(offset: any, limit: any, filter: any, search: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: filter,
        url: `${baseUrl}/admin/reports?offset=${offset}&limit=${limit}&search=${search}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getFilters() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/filters`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getFiltersAPR(field: FilterAPR, searchString = '', limit = 15) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/filtersAPR?field=${field}&searchString=${searchString}&limit=${limit}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getRemainingForm() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/reports/remaining_forms`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getStartedForms() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/reports/started_forms`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getFinishedForms() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/reports/submitted_forms`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getAPREntities(selectedFilters: APREntityArrayFields, rowsPerPage: number, page: number) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/APREntities?limit=${rowsPerPage}&offset=${page}`,
        data: selectedFilters,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getAPREntityByPIB(pib: string): Promise<{ status: number; data: APREntityAutoComplete }> {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/apr/pib/${pib}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getAPREntityByMaticniBroj(maticniBroj: string): Promise<{ status: number; data: APREntityAutoComplete }> {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/apr/mb/${maticniBroj}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function resendValidationEmail(email: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/resend/register`,
        data: { email: email },
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function sendResetPasswordEmail(email: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/resend/password`,
        data: { email: email },
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function resetPassword(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/reset/password`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function deleteFile(id: any, fileName: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/common/file/delete?id=${id}&fileName=${fileName}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getFilesForForm(id: any, column: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/common/form/files?id=${id}&column=${column}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getForm1(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form1?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForm2(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form2?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForm3(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form3?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForm4(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form4?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForm5(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form5?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getForm6(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/form6?formId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getAllCities() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/all-cities`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function deleteCity(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'DELETE',
        url: `${baseUrl}/user/city/${id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function updateCity(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/update-city`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getUsers(limit: number, offset: number, searhTerm: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/users?limit=${limit}&offset=${offset}`,
        data: searhTerm,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getAllHelp(limit: number, offset: number, term: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/help/all?limit=${limit}&offset=${offset}&term=${term}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getHelpByName(name: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/help/by/${name}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function editHelpContent(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/help/edit`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getUser(userId: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/user?userId=${userId}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function editUser(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/edit/user`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function createAdminUser(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/create/admin`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function deleteUser(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/user?userId=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function createMessage(data: MessageInputs) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/create-message`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function searchMessages(search: string, limit: number, offset: number) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/messages/search?search=${search}&limit=${limit}&offset=${offset}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function updateUser(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/edit`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function changePassword(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/change-password`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function newEmailVerify(email: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/change-email`,
        data: { email },
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function newEmailChange(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/change-email/verify`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getLegalInfo() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/legal`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function createLegalInfo(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/legal`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function viewFile(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/common/file?id=${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getParentCategories() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/parents`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getChildCategories(id: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/children/${id}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function assignUserCategory(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/user/assign/category`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

// Categories
export async function getAllForms() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/forms`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getCategoriesInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_categories_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getEntitiesInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_entities_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getSubcategoriesInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_subcategories_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getCategoriesNotInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_categories_not_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getEntitiesNotInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_entities_not_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getSubcategoriesNotInForm(form: string) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/get_subcategories_not_in_form?form=${form}`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function adminAssignUserCategory(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/admin/assign-user-category`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function moveCategoriesToForms(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/move_form_to_category`,
        data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function removeCategoriesToForms(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/remove_form_to_category`,
        data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function moveEntitiesToForms(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/move_form_to_entity`,
        data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function removeEntitiesToForms(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/remove_form_to_entity`,
        data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

export async function getAllCategories() {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category`,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function updateCategory(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/category/update`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}
export async function getAnalyticsDataForForm1(data: any) {
    const token = localStorage.getItem('accessToken')
    const options: AxiosRequestConfig = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        url: `${baseUrl}/analytics/form1`,
        data: data,
    }
    return axios(options)
        .then((response) => handleSuccess(response))
        .catch((error) => handleError(error))
}

function handleError(error: any) {
    return { status: error?.response?.status, data: error?.response?.data }
}
function handleSuccess(response: any) {
    return { status: response.status, data: response.data }
}
