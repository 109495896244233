import ActionButtons from '../../../../components/ActionButtons'
import { StepInterface } from './Step1'
import NoAnalytics from '../../Common/NoAnalytics'

function Step17({ control, nextStep, previousStep, previousButtonDisabled = false, adminView }: StepInterface) {
    return (
        <div>
            <h1 className="step-name">
                17. Број пријављених случајева насиља у органу јавне власти или код послодавца по основу пола, односно рода, разврстаних по облику насиља, полу жртве насиља и полу
                извршиоца насиља, као и међусобном односу жртве и извршиоца насиља, у години вођења евиденције:
            </h1>

            <NoAnalytics />
            <ActionButtons nextButton={nextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} />
        </div>
    )
}

export default Step17
