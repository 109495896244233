import React, { useEffect, useState } from 'react'
import { Box, Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ReactComponent as Right } from '../../assets/svg/Right.svg'
import { Form, Category, Entity, Selectable } from './types'

import { FormSelector } from './FormSelector'
import { CategoryList } from './CategoriesList'
import {
    getAllForms,
    getCategoriesInForm,
    getCategoriesNotInForm,
    getEntitiesInForm,
    getEntitiesNotInForm,
    getSubcategoriesInForm,
    getSubcategoriesNotInForm,
    moveCategoriesToForms,
    moveEntitiesToForms,
    removeCategoriesToForms,
    removeEntitiesToForms,
} from '../../server/server'
import HelpButton from '../../components/Common/HelpButton'
import { EntitiesList } from './EntitiesList'

const assignedFunctions = (type: 'category' | 'subcategory' | 'entity') => {
    if (type == 'category') return getCategoriesInForm
    else if (type == 'subcategory') return getSubcategoriesInForm
    else return getEntitiesInForm
}
const unassignedFunctions = (type: 'category' | 'subcategory' | 'entity') => {
    if (type == 'category') return getCategoriesNotInForm
    else if (type == 'subcategory') return getSubcategoriesNotInForm
    else return getEntitiesNotInForm
}

const ConnectCategoriesWithForms: React.FC = () => {
    const [forms, setForms] = useState<Form[]>([])
    const [selectedForm, setSelectedForm] = useState<Form | null>(null)
    const [assignedCategories, setAssignedCategories] = useState<Category[]>([])
    const [unassignedCategories, setUnassignedCategories] = useState<Category[]>([])
    const [assignedEntities, setAssignedEntities] = useState<Entity[]>([])
    const [unassignedEntities, setUnassignedEntities] = useState<Entity[]>([])
    const [type, setType] = useState<'category' | 'subcategory' | 'entity'>('category')

    useEffect(() => {
        loadForms()
    }, [])

    useEffect(() => {
        if (selectedForm) {
            loadAssignedCategories(selectedForm?.code)
            loadUnassignedCategories(selectedForm?.code)
        }
    }, [type])

    const loadForms = async () => {
        const resp = await getAllForms()
        if (resp.status === 200) {
            setForms(resp.data)
            onSelectForm(resp.data[0])
        }
    }

    const onSelectForm = (form: Form) => {
        setSelectedForm(form)
        loadAssignedCategories(form.code)
        loadUnassignedCategories(form.code)
    }

    const loadAssignedCategories = async (formCode: string) => {
        const resp = await assignedFunctions(type)(formCode)
        if (resp.status === 200) {
            if (type === 'entity') setAssignedEntities(resp.data)
            else setAssignedCategories(resp.data)
        }
    }

    const loadUnassignedCategories = async (formCode: string) => {
        const resp = await unassignedFunctions(type)(formCode)
        if (resp.status === 200) {
            if (type === 'entity') setUnassignedEntities(resp.data)
            else setUnassignedCategories(resp.data)
        }
    }

    const handleCategorySelect = (id: string, categories: Category[], setValues: React.Dispatch<React.SetStateAction<Category[]>>) => {
        onSelect<Category>(id, categories, setValues)
    }

    const handleEntitySelect = (id: string, entities: Entity[], setValues: React.Dispatch<React.SetStateAction<Entity[]>>) => {
        console.log(id)

        onSelect<Entity>(id, entities, setValues)
    }

    const onSelect = <T extends Selectable>(id: string, array: T[], setValues: React.Dispatch<React.SetStateAction<T[]>>) => {
        setValues((prevArray) => {
            const newArray = [...prevArray]
            const index = newArray.findIndex((item) => item.id === id)

            if (index !== -1) {
                newArray[index] = {
                    ...newArray[index],
                    selected: !newArray[index].selected,
                }
            }

            return newArray
        })
    }

    const moveValues = async (array: Category[], callbackFunction: typeof moveCategoriesToForms | typeof removeCategoriesToForms) => {
        if (!selectedForm) return

        const data = {
            formName: selectedForm.code,
            categoriesId: array.filter((item) => item.selected).map((item) => item.id),
        }
        const resp = await callbackFunction(data)
        if (resp.status === 201) {
            loadAssignedCategories(selectedForm.code)
            loadUnassignedCategories(selectedForm.code)
        }
    }
    const moveValuesEntities = async (array: Entity[], callbackFunction: typeof moveEntitiesToForms | typeof removeEntitiesToForms) => {
        if (!selectedForm) return

        const data = {
            formName: selectedForm.code,
            extendedIds: array.filter((item) => item.selected).map((item) => item.id),
        }
        const resp = await callbackFunction(data)
        if (resp.status === 201) {
            loadAssignedCategories(selectedForm.code)
            loadUnassignedCategories(selectedForm.code)
        }
    }

    return (
        <div className="!w-full px-[100px] pb-[52px] h-full gap-[20px] flex flex-col py-[52px] ">
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, mb: 2 }}>
                <h2 className="text-[24px] font-light max-w-[800px]">Управљање категоријама и привредним ентитетима</h2>
                {/* <HelpButton name="admin_help" /> */}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, justifyContent: 'space-between' }}>
                <div className="flex flex-row items-center gap-[5px]">
                    <label htmlFor="">Управљај на основу:</label>
                    <div className="flex gap-[10px]">
                        <label>
                            <RadioGroup row name="categoryType" value={type} onChange={(event) => setType(event.target.value as 'category' | 'subcategory' | 'entity')}>
                                <FormControlLabel value="category" control={<Radio />} label="Категорија" />
                                <FormControlLabel value="subcategory" control={<Radio />} label="Подкатегорија" />
                                <FormControlLabel value="entity" control={<Radio />} label="Привредни ентитети" />
                            </RadioGroup>
                        </label>
                    </div>
                </div>
                <FormSelector text={'Одаберите образац'} forms={forms} selectedForm={selectedForm} onSelectForm={onSelectForm} />
            </Box>
            {['category', 'subcategory'].includes(type) && (
                <div className="w-full flex flex-row justify-between flex-1 gap-12">
                    <CategoryList categories={unassignedCategories} onSelect={(id) => handleCategorySelect(id, unassignedCategories, setUnassignedCategories)} type={type} />
                    <div className="flex flex-col gap-[20px] items-center justify-center">
                        <div className="flex flex-col">
                            <label htmlFor="Dodaj kao у категорију">Додај као подносиоца</label>
                            <Button variant="contained" color="inherit" onClick={() => moveValues(unassignedCategories, moveCategoriesToForms)}>
                                <Right />
                            </Button>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="Dodaj kao у категорију">Избаци из подносиоца</label>
                            <Button variant="contained" color="inherit" onClick={() => moveValues(assignedCategories, removeCategoriesToForms)}>
                                <Right rotate={180} className="rotate-180" />
                            </Button>
                        </div>
                    </div>
                    <CategoryList categories={assignedCategories} onSelect={(id) => handleCategorySelect(id, assignedCategories, setAssignedCategories)} type={type} />
                </div>
            )}
            {type == 'entity' && (
                <div className="w-full flex flex-row justify-between flex-1 gap-12">
                    <EntitiesList entities={unassignedEntities} onSelect={(id) => handleEntitySelect(id, unassignedEntities, setUnassignedEntities)} type={type} />
                    <div className="flex flex-col gap-[20px] items-center justify-center">
                        <div className="flex flex-col">
                            <label htmlFor="Dodaj kao у категорију">Додај као подносиоца</label>
                            <Button variant="contained" color="inherit" onClick={() => moveValuesEntities(unassignedEntities, moveEntitiesToForms)}>
                                <Right />
                            </Button>
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="Dodaj kao у категорију">Избаци из подносиоца</label>
                            <Button variant="contained" color="inherit" onClick={() => moveValuesEntities(assignedEntities, removeEntitiesToForms)}>
                                <Right rotate={180} className="rotate-180" />
                            </Button>
                        </div>
                    </div>
                    <EntitiesList entities={assignedEntities} onSelect={(id) => handleEntitySelect(id, assignedEntities, setAssignedEntities)} type={type} />
                </div>
            )}
        </div>
    )
}

export default ConnectCategoriesWithForms
