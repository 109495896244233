import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import Textbox from '../../../components/Common/Textbox'
import PositiveDecimalInputSeparate from '../../../components/Common/PositiveDecimalInputSeparate'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const disabledNext = watchedValues.o4_k6_ocena_text?.length === 0 || watchedValues.o4_k6_ocena_broj === undefined || typeof watchedValues.o4_k6_ocena_broj !== 'number'

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    const gradeValidation = (value: number) => {
        return value >= 1 && value <= 5
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">Оцена ефеката примене посебних мера</h1>
            <div className=" flex flex-row gap-2">
                <Textbox
                    name="o4_k6_ocena_text"
                    control={control}
                    maxNumberOfCharacters={2048}
                    errors={errors}
                    isRequired={adminView ? false : 'Попуните обавезно поље.'}
                    disabled={adminView}
                />
                <HelpButton name="o4-k6" />
            </div>
            <h1 className="step-name mb-[20px] mt-[10px]">Оцена степена реализације покрајинског или локалног буџета са становишта родне перспективе (од 1 до 5)</h1>
            <PositiveDecimalInputSeparate
                name="o4_k6_ocena_broj"
                placeholder={'1-5'}
                control={control}
                errors={errors}
                isRequired={!adminView}
                disabled={adminView}
                validationFunction={gradeValidation}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
