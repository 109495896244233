import { TblOblast4 } from '../../../models/Form4/Form4.entity'
import '../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'
import { StepInterface } from './Step1'
import HelpButton from '../../../components/Common/HelpButton'

function Step8({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<TblOblast4>({ control })
    const fields: ('o4_k8_br1' | 'o4_k8_br2' | 'o4_k8_br3' | 'o4_k8_br4' | 'o4_k8_br5' | 'o4_k8_br6')[] = [
        'o4_k8_br1',
        'o4_k8_br2',
        'o4_k8_br3',
        'o4_k8_br4',
        'o4_k8_br5',
        'o4_k8_br6',
    ]
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const header = [
        'Укупна вредност неплаћеног кућног рада особа оба пола, нa годишњем нивоу исказана у динаримa (РСД):',
        'Укупан број сати особа оба пола које проводе у неплаћеном кућном раду, исказаних на годишњем нивоу',
        'Укупна вредност у РСД које жене у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупан број сати које жене у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупна вредност у РСД које мушкарци у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
        'Укупан број сати које мушкарци у јединици територијалне аутономије или јединици локалне самоуправе проводе у неплаћеном кућном раду, исказаних на годишњем нивоу:',
    ]

    const rows = [
        { field: 'o4_k8_br1', decimal: true },
        { field: 'o4_k8_br2', decimal: false },
        { field: 'o4_k8_br3', decimal: true },
        { field: 'o4_k8_br4', decimal: false },
        { field: 'o4_k8_br5', decimal: true },
        { field: 'o4_k8_br6', decimal: false },
    ]

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <h1 className="step-name mb-[20px]">
                Подаци о неплаћеном кућном раду на нивоу јединице територијалне аутономије или јединице локалне самоуправе исказани за годину извештавања, разврстани по полу:
            </h1>
            <div className=" flex flex-row gap-2">
                <table className="simple-table">
                    <thead>
                        <tr className="simple-table-header">
                            {header.map((head, index) => (
                                <th className="simple-table-cell-tall" key={index}>
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="simple-table-row pt-[6px]">
                            {rows.map((row, id) => (
                                <td key={id} className="simple-table-cell editable !border-none !px-[0px] pt-">
                                    <PositiveNumberInputCell
                                        name={row.field}
                                        control={control}
                                        defaultValue=""
                                        decimal={row.decimal}
                                        errors={errors}
                                        isRequired={!adminView}
                                        disabled={adminView}
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                <HelpButton name="o4-k8" />
            </div>
            <div className="h-[20px]">{Object.keys(errors).length > 0 && <p className="text-[0.75rem] ml-[50px] text-[#d32f2f]">Попуните обавезно поље.</p>}</div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step8
