import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Entity } from './types'

interface EntitiesListProps {
    entities: Entity[]
    onSelect: (id: string) => void
    type?: 'category' | 'subcategory' | 'entity'
}

export const EntitiesList: React.FC<EntitiesListProps> = ({ entities, onSelect, type }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [pibSearch, setPibSearch] = useState('')

    useEffect(() => {
        setSearchTerm('')
    }, [type])

    return (
        <div className="flex-1 h-full  max-h-[600px] min-h-[600px] flex flex-col ">
            <TextField label="Претражи" variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: '100%', marginBottom: 10 }} />
            <TextField label="Пиб" variant="outlined" value={pibSearch} onChange={(e) => setPibSearch(e.target.value)} style={{ width: '100%', marginBottom: 10 }} />
            <div className=" flex-1 border overflow-y-auto border-[gray] rounded-md gap-[5px] flex flex-col p-[10px]">
                {entities
                    .filter((e) => e?.subjectName?.toLowerCase()?.includes(searchTerm?.toLowerCase()) && `${e?.pib ?? ''}`.includes(pibSearch))
                    .map((entity) => (
                        <Button variant="contained" color={entity.selected ? 'primary' : 'inherit'} key={entity.id} onClick={() => onSelect(entity.id)}>
                            <div>{entity.subjectName}</div>
                            {!!entity.pib && <span className="ml-[5px]">ПИБ: {entity.pib}</span>}
                        </Button>
                    ))}
            </div>
        </div>
    )
}
