import { FieldValues } from 'react-hook-form'
import { TblOblast1 } from '../models/Form1/Form1.entity'
import { CATEGORY_ENUM } from '../models/Organization.entity'

export const getPercentage = (number1: any, number2: any) => {
    if (number1 === undefined || number1 === null || isNaN(number1) || number2 === undefined || number2 === null || isNaN(number2)) {
        return ''
    }
    if (number2 === 0) return 0

    const percentage = (Number(number1) / Number(number2)) * 100
    const roundedPercentage = Math.round(percentage * 100) / 100
    return roundedPercentage.toFixed(2)
}

export function sumValues<T extends FieldValues>(values: T, fields: (keyof T)[], decimal = false): number {
    const sum = fields.reduce((acc, field) => {
        const value = values[field]
        return acc + (typeof value === 'number' ? value : 0)
    }, 0)

    return decimal ? parseFloat(sum.toFixed(2)) : sum
}

export const twoDigits = (number: number) => `0${number}`.slice(-2)

export function getEnumValues(enumObj: any): string[] {
    return Object.values(enumObj)
}
