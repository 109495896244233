import { Pagination, Paper, Table, TableCell, TableContainer, TablePagination, TableRow, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { EmptyTablePaginationActions } from '../Components/EmptyTablePaginationActions'
import AutoComplete from '../../../components/Common/AutoComplete'
import { getFiltersAPR, getAPREntities, APREntity, APREntityArrayFields } from '../../../server/server'
import HelpButton from '../../../components/Common/HelpButton'

type AutoCompleteType = 'pib' | 'maticniBroj' | 'naziv' | 'adresa' | 'ovlascenoLice' | 'pravnaForma'

const typeMapping: AutoCompleteType[] = ['pib', 'maticniBroj', 'naziv', 'adresa', 'pravnaForma']

const tableHeaders = ['ПИБ', 'Матични број', 'Назив', 'Адреса', 'Овлашћено лице', 'Правна форма']

const headerKeyMap: { [key: string]: keyof APREntity } = {
    ПИБ: 'pib',
    'Матични број': 'maticniBroj',
    Назив: 'naziv',
    Адреса: 'adresa',
    'Овлашћено лице': 'ovlascenoLice',
    'Правна форма': 'pravnaForma',
}

const SELECT_ALL = 'Сви'

const CorporateEntitiesSearch = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filters, setFilters] = useState<APREntityArrayFields>({
        pib: [],
        maticniBroj: [],
        naziv: [],
        adresa: [],
        ovlascenoLice: [],
        pravnaForma: [],
    })
    const [selectedFilters, setSelectedFilters] = useState<APREntityArrayFields>({
        pib: [],
        maticniBroj: [],
        naziv: [],
        adresa: [],
        ovlascenoLice: [],
        pravnaForma: [],
    })
    const [tableData, setTableData] = useState<APREntity[]>([])
    const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
    const [openAutoCompleteIndex, setOpenAutoCompleteIndex] = useState<number | null>(null)
    const autoCompleteRefs = useRef<(HTMLDivElement | null)[]>([])

    const handleChangePage = (event: unknown, newPage: number) => {
        newPage = newPage - 1
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        for (const field in filters) {
            getFiltersForTable(field as keyof APREntity)
        }
    }, [])

    useEffect(() => {
        getFormsData()
    }, [page, rowsPerPage, selectedFilters])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (openAutoCompleteIndex !== null) {
                const currentRef = autoCompleteRefs.current[openAutoCompleteIndex]
                if (currentRef && !currentRef.contains(event.target as Node)) {
                    setOpenAutoCompleteIndex(null)
                }
            }
        }

        const handleTabPress = (event: KeyboardEvent) => {
            if (event.key === 'Tab' && openAutoCompleteIndex !== null) {
                setOpenAutoCompleteIndex(null)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleTabPress)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleTabPress)
        }
    }, [openAutoCompleteIndex])

    const getFormsData = async () => {
        try {
            const resp = await getAPREntities(selectedFilters, rowsPerPage, page)
            if (resp.status === 201) {
                console.log(resp.data.items[0])
                const newData: APREntity[] = resp.data.items.map((item: any) => ({
                    pib: item.pib,
                    maticniBroj: item.maticniBroj,
                    naziv: item.naziv,
                    adresa: item.punaAdresa.replace(/(^\/|\/\s|\s\/|\/$)/g, ''),
                    ovlascenoLice: [item.ovlascenoLiceIme, item.ovlascenoLicePrezime].join(' '),
                    pravnaForma: item.pravnaForma,
                    status: item.status,
                }))
                setTableData(newData)
                setTotalCount(resp.data.totalCount)
            }
        } catch (error) {
            console.error('Failed to fetch data', error)
        }
    }

    const getFiltersForTable = async (field: keyof APREntity, searchString = '') => {
        try {
            const resp = await getFiltersAPR(field, searchString)
            if (resp.status === 200) {
                setFilters((prevFilters) => {
                    return { ...prevFilters, [field]: resp.data }
                })
            }
        } catch (error) {
            console.error('Failed to fetch filters', error)
        }
    }

    const pageCount = Math.ceil((totalCount || 0) / rowsPerPage)

    const pravnaFormaOptions = filters?.pravnaForma || []

    const headerMapping = Object.entries(headerKeyMap).map(([header, value]) => ({
        header,
        value,
    }))

    return (
        <div className="!w-full px-[100px] bg-[#ffffff] mt-[52px]">
            <div className="flex flex-row items-center justify-between gap-8 mb-4">
                <h2 className="text-[24px] font-light max-w-[800px]">Привредни ентитети</h2>
                <HelpButton name="corporate_help" />
            </div>
            <div className="!w-full py-10 min-h-full">
                <Paper>
                    <TableContainer>
                        <Table className="overflow-x-auto w-full">
                            <TableRow>
                                {headerMapping.map((item, index) => (
                                    <TableCell className="border border-[#E0E0E0] min-w-[150px]" key={index}>
                                        <div className="!flex !flex-row justify-between items-center px-2">{item.header}</div>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                {tableHeaders.map((header: string, index: number) => (
                                    <TableCell className="min-w-[150px] border border-[#E0E0E0] !p-0">
                                        <div ref={(el) => (autoCompleteRefs.current[index] = el)}>
                                            {index < 5 ? (
                                                <TextField
                                                    type="text"
                                                    className="w-full p-2 border border-gray-300 rounded"
                                                    placeholder="Претрага"
                                                    value={selectedFilters[headerKeyMap[header]]}
                                                    onChange={(e: any) => {
                                                        const value = e.target.value
                                                        setSelectedFilters((prevFilter: any) => {
                                                            return { ...prevFilter, [headerKeyMap[header]]: value }
                                                        })
                                                        setPage(0)
                                                    }}
                                                    sx={{
                                                        borderRadius: 0,
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '.MuiSvgIcon-root ': {
                                                            fill: '#e0e0e0 !important',
                                                        },
                                                    }}
                                                />
                                            ) : (
                                                <AutoComplete
                                                    borderColor="transparent"
                                                    key={index}
                                                    type={typeMapping[index]}
                                                    setSelectedFilters={(getValues: any) => {
                                                        setSelectedFilters((prevFilter: any) => {
                                                            let values = getValues()
                                                            if (values.includes(SELECT_ALL)) {
                                                                values = []
                                                            }
                                                            return { ...prevFilter, [headerKeyMap[header]]: values }
                                                        })
                                                        setPage(0)
                                                    }}
                                                    options={index === 5 ? [SELECT_ALL, ...pravnaFormaOptions] : [SELECT_ALL]}
                                                    isOpen={openAutoCompleteIndex === index}
                                                    onOpen={() => setOpenAutoCompleteIndex(index)}
                                                    onClose={() => setOpenAutoCompleteIndex(null)}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>

                            {tableData.map((item, index) => (
                                <TableRow key={index}>
                                    {tableHeaders.map((header) => (
                                        <TableCell className="max-w-[150px] border border-[#E0E0E0]" key={header}>
                                            {item[headerKeyMap[header]]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <div className="mt-[20px] flex flex-row justify-end items-center gap-[40px]">
                <TablePagination
                    component="div"
                    count={totalCount || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[3, 5, 10, 15]}
                    labelRowsPerPage="Редова по страни:"
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} од ${count}`}
                    ActionsComponent={EmptyTablePaginationActions}
                />
                <Pagination
                    count={pageCount}
                    page={page + 1}
                    onChange={handleChangePage}
                    color="primary"
                    shape="circular"
                    sx={{
                        '& .MuiPaginationItem-root': {
                            '&.Mui-selected': {
                                background: '#A2CEFF',
                                // borderRadius: '50%',
                            },
                        },
                    }}
                    showFirstButton
                    showLastButton
                />
            </div>
        </div>
    )
}

export default CorporateEntitiesSearch
